/* eslint-disable eqeqeq */
import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Input from "../../components/Input";
import Select from "../../components/Select";
import { SINO } from "../../utils/selectors";
import { useNavigate } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";

export default function Abandona() {
  const navigation = useNavigate();
  const [values, setvalues] = React.useState({});
  const [familia, setFamilia] = React.useState();

  React.useEffect(() => {
    const abandona = localStorage.getItem("abandona");
    if (abandona) {
      setvalues(JSON.parse(abandona));
    }
    return () => { };
  }, []);

  React.useEffect(() => {
    const esFamilia = localStorage.getItem("familiar");
    setFamilia(esFamilia);
  }, []);

  const validateSchema = Yup.object({
    aband: Yup.string().required("Campo requerido"),
    abandmeses: Yup.string().when("aband", {
      is: "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    abandhijos: Yup.string().when("aband", {
      is: "Si",
      then: () => Yup.number().typeError('Sólo números').required("Campo requerido"),
    }),
    abandnietos: Yup.string().when("aband", {
      is: "Si",
      then: () => Yup.number().typeError('Sólo números').required("Campo requerido"),
    }),
    abandpareja: Yup.string().when("aband", {
      is: "Si",
      then: () => Yup.number().typeError('Sólo números').required("Campo requerido"),
    }),
    abandyernonuera: Yup.string().when("aband", {
      is: "Si",
      then: () => Yup.number().typeError('Sólo números').required("Campo requerido"),
    }),
    abandotros: Yup.string().when("aband", {
      is: "Si",
      then: () => Yup.number().typeError('Sólo números').required("Campo requerido"),
    }),
    abandtestigo: Yup.string().when("aband", {
      is: "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    abandintoxicado:
      familia === "Si" &&
      Yup.string().when("aband", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    abandeconomico:
      familia === "Si" &&
      Yup.string().when("aband", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    abanddomicilio:
      familia === "Si" &&
      Yup.string().when("aband", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    abanddefensa:
      familia == "Si" &&
      Yup.string().when("aband", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    abanddefensahijos:
      familia == "Si" &&
      Yup.string().when("aband", {
        is: "Si",
        then: () => Yup.string().when("abanddefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    abanddefensanietos:
      familia == "Si" &&
      Yup.string().when("aband", {
        is: "Si",
        then: () => Yup.string().when("abanddefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    abanddefensapareja:
      familia == "Si" &&
      Yup.string().when("aband", {
        is: "Si",
        then: () => Yup.string().when("abanddefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    abanddefensayerno:
      familia == "Si" &&
      Yup.string().when("aband", {
        is: "Si",
        then: () => Yup.string().when("abanddefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    abanddefensaotros:
      familia == "Si" &&
      Yup.string().when("aband", {
        is: "Si",
        then: () => Yup.string().when("abanddefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    abandinforma:
      familia === "Si" &&
      Yup.string().when("aband", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    abandinformasacerdote:
      familia === "Si" &&
      Yup.string().when("aband", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    abandinformahospital:
      familia === "Si" &&
      Yup.string().when("aband", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    abandinformabienestar:
      familia === "Si" &&
      Yup.string().when("aband", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    abandinformapolicia:
      familia === "Si" &&
      Yup.string().when("aband", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    abandnoinformanosabe:
      familia == "Si" && Yup.string()
        .when(["aband", "abandinforma"], {
          is: (aband, abandinforma) => aband == "Si" && abandinforma == "No",
          then: () => Yup.string().required("Campo requerido"),
        }),
    abandnoinformanoapoyo:
      familia == "Si" && Yup.string()
        .when(["aband", "abandinforma"], {
          is: (aband, abandinforma) => aband == "Si" && abandinforma == "No",
          then: () => Yup.string().required("Campo requerido"),
        }),
    abandnoinformanoperjudicar:
      familia == "Si" && Yup.string()
        .when(["aband", "abandinforma"], {
          is: (aband, abandinforma) => aband == "Si" && abandinforma == "No",
          then: () => Yup.string().required("Campo requerido"),
        }),
    abandnoinformaempeoraria:
      familia == "Si" && Yup.string()
        .when(["aband", "abandinforma"], {
          is: (aband, abandinforma) => aband == "Si" && abandinforma == "No",
          then: () => Yup.string().required("Campo requerido"),
        })
  })

  // const runValidations = () => {
  //   validateSchema
  //     .validate({ abortEarly: false })
  //     .then((responseData) => {
  //       console.log("no validation errors");
  //       console.log(responseData);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       console.log(err.name); // ValidationError
  //       console.log(err.errors);
  //     });
  // };

  return (
    <div>
      <Formik
        initialValues={{
          aband: values?.aband || "",
          abandmeses: values?.abandmeses || "",
          abandhijos: values?.abandhijos || "",
          abandnietos: values?.abandnietos || "",
          abandpareja: values?.abandpareja || "",
          abandyernonuera: values?.abandyernonuera || "",
          abandotros: values?.abandotros || "",
          abandtestigo: values?.abandtestigo || "",
          abandintoxicado: values?.abandintoxicado || "",
          abandeconomico: values?.abandeconomico || "",
          abanddomicilio: values?.abanddomicilio || "",
          abanddefensa: values?.abanddefensa || "",
          abanddefensahijos: values?.abanddefensahijos || "",
          abanddefensanietos: values?.abanddefensanietos || "",
          abanddefensapareja: values?.abanddefensapareja || "",
          abanddefensayerno: values?.abanddefensayerno || "",
          abanddefensaotros: values?.abanddefensaotros || "",
          abandinforma: values?.abandinforma || "",
          abandinformasacerdote: values?.abandinformasacerdote || "",
          abandinformahospital: values?.abandinformahospital || "",
          abandinformabienestar: values?.abandinformabienestar || "",
          abandinformapolicia: values?.abandinformapolicia || "",
          abandnoinformanosabe: values?.abandnoinformanosabe || "",
          abandnoinformanoapoyo: values?.abandnoinformanoapoyo || "",
          abandnoinformanoperjudicar: values?.abandnoinformanoperjudicar || "",
          abandnoinformaempeoraria: values?.abandnoinformaempeoraria || "",
        }}
        enableReinitialize={true}
        onSubmit={(values) => {
          localStorage.setItem("abandona", JSON.stringify(values));
          navigation("/vp");
        }}
        validationSchema={validateSchema}
      >
        {({ values, handleChange, resetForm, setFieldValue, touched, errors }) => (
          <Form>
            <div className="grid grid-cols-1 gap-y-6 md:grid-cols-12 gap-x-6 p-2">
              {/* <div>
                <button onClick={runValidations}>run validations</button>
              </div> */}
              <div className="col-span-12">
                <Select
                  label="3. ¿La PAM ha sido expulsado/a del hogar por parte de alguno de sus familiares (hijos, nietos, pareja)?"
                  name="aband"
                  value={values.aband}
                  onChange={(e) => {
                    handleChange(e);
                    if (e.target.value === "No") {
                      const aband = e.target.value;
                      setvalues({
                        aband: aband,
                        abandmeses: "",
                        abandhijos: "",
                        abandnietos: "",
                        abandpareja: "",
                        abandyernonuera: "",
                        abandotros: "",
                        abandtestigo: "",
                        abandintoxicado: "",
                        abandeconomico: "",
                        abanddomicilio: "",
                        abanddefensa: "",
                        abanddefensahijos: "",
                        abanddefensanietos: "",
                        abanddefensapareja: "",
                        abanddefensayerno: "",
                        abanddefensaotros: "",
                        abandinforma: "",
                        abandinformasacerdote: "",
                        abandinformahospital: "",
                        abandinformabienestar: "",
                        abandinformapolicia: "",
                        abandnoinformanosabe: "",
                        abandnoinformanoapoyo: "",
                        abandnoinformanoperjudicar: "",
                        abandnoinformaempeoraria: "",
                      });
                      resetForm({
                        values: {
                          aband: aband,
                          abandmeses: "",
                          abandhijos: "",
                          abandnietos: "",
                          abandpareja: "",
                          abandyernonuera: "",
                          abandotros: "",
                          abandtestigo: "",
                          abandintoxicado: "",
                          abandeconomico: "",
                          abanddomicilio: "",
                          abanddefensa: "",
                          abanddefensahijos: "",
                          abanddefensanietos: "",
                          abanddefensapareja: "",
                          abanddefensayerno: "",
                          abanddefensaotros: "",
                          abandinforma: "",
                          abandinformasacerdote: "",
                          abandinformahospital: "",
                          abandinformabienestar: "",
                          abandinformapolicia: "",
                          abandnoinformanosabe: "",
                          abandnoinformanoapoyo: "",
                          abandnoinformanoperjudicar: "",
                          abandnoinformaempeoraria: "",
                        },
                      });
                    }
                  }}
                  array={SINO}
                />
                {touched.aband && errors.aband ? (
                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                    <ErrorIcon fontSize="small" /> {errors.aband}
                  </div>
                ) : null}
              </div>
              {values.aband === "Si" && (
                <>
                  <div className="col-span-12">
                    <Input
                      label="3.2 ¿Desde hace cuánto tiempo ocurre esto? (indica con palabras, si años, meses o días)"
                      name="abandmeses"
                      type="text"
                      placeholder="Inserta cuanto tiempo"
                      value={values.abandmeses}
                      onChange={handleChange}
                    />
                    {touched.abandmeses && errors.abandmeses ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.abandmeses}
                      </div>
                    ) : null}
                  </div>
                  <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                    <h2 className="text-base font-bold leading-7 text-gray-900">
                      ¿Quién lo hizo y cuántas veces ocurrió en el último mes?
                    </h2>
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Hijos/Hijas"
                      name="abandhijos"
                      placeholder="Hijos"
                      value={values.abandhijos}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.abandhijos && errors.abandhijos ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.abandhijos}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Nietos/Nietas"
                      name="abandnietos"
                      placeholder="Nietos"
                      value={values.abandnietos}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.abandnietos && errors.abandnietos ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.abandnietos}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Pareja"
                      name="abandpareja"
                      placeholder="Pareja"
                      value={values.abandpareja}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.abandpareja && errors.abandpareja ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.abandpareja}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Yerno/Nuera"
                      name="abandyernonuera"
                      placeholder="Yerno/Nuera"
                      value={values.abandyernonuera}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.abandyernonuera && errors.abandyernonuera ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.abandyernonuera}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Otros"
                      name="abandotros"
                      placeholder="Otros"
                      value={values.abandotros}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.abandotros && errors.abandotros ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.abandotros}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Select
                      label="No sabe, pero lo atestiguó"
                      name="abandtestigo"
                      value={values.abandtestigo}
                      onChange={handleChange}
                      array={SINO}
                    />
                    {touched.abandtestigo && errors.abandtestigo ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.abandtestigo}
                      </div>
                    ) : null}
                  </div>
                  {familia === "Si" && (
                    <>
                      <div className="border-t col-span-12"></div>
                      <div className="col-span-12">
                        <Select
                          label="3.3 ¿Estos episodios ocurren cuando el agresor se encuentra intoxicado (tomó alcohol, alguna droga)?"
                          name="abandintoxicado"
                          value={values.abandintoxicado}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.abandintoxicado && errors.abandintoxicado ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.abandintoxicado}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="¿El/la/los agresores viven en el mismo domicilio que la PAM?"
                          name="abanddomicilio"
                          value={values.abanddomicilio}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.abanddomicilio && errors.abanddomicilio ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.abanddomicilio}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="¿Estos episodios ocurren cuando el agresor tiene problemas económicos?"
                          name="abandeconomico"
                          value={values.abandeconomico}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.abandeconomico && errors.abandeconomico ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.abandeconomico}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-12">
                        <Select
                          label="Cuando estos episodios ocurren, ¿alguien entra en su defensa?"
                          name="abanddefensa"
                          value={values.abanddefensa}
                          onChange={(e) => {
                            handleChange(e);
                            if (e.target.value === "No") {
                              setFieldValue('abanddefensahijos', "")
                              setFieldValue('abanddefensanietos', "")
                              setFieldValue('abanddefensapareja', "")
                              setFieldValue('abanddefensayerno', "")
                              setFieldValue('abanddefensaotros', "")
                            }
                          }}
                          array={SINO}
                        />
                        {touched.abanddefensa && errors.abanddefensa ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.abanddefensa}
                          </div>
                        ) : null}
                      </div>
                      {values.abanddefensa == "Si" && (
                        <>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Hijos/Hijas"
                              name="abanddefensahijos"
                              placeholder="Hijos"
                              value={values.abanddefensahijos}
                              onChange={handleChange}
                              type="text"
                            />
                            {touched.abanddefensahijos && errors.abanddefensahijos ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.abanddefensahijos}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Nietos/Nietas"
                              name="abanddefensanietos"
                              placeholder="Nietos"
                              value={values.abanddefensanietos}
                              onChange={handleChange}
                              type="text"
                            />
                            {touched.abanddefensanietos && errors.abanddefensanietos ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.abanddefensanietos}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Pareja/esposo/esposa"
                              name="abanddefensapareja"
                              placeholder="Pareja/esposo/esposa"
                              value={values.abanddefensapareja}
                              onChange={handleChange}
                              type="text"
                            />
                            {touched.abanddefensapareja && errors.abanddefensapareja ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.abanddefensapareja}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Yerno/Nuera"
                              name="abanddefensayerno"
                              placeholder="Yerno"
                              value={values.abanddefensayerno}
                              onChange={handleChange}
                              type="text"
                            />
                            {touched.abanddefensayerno && errors.abanddefensayerno ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.abanddefensayerno}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Otras personas que no son familiares directos"
                              name="abanddefensaotros"
                              placeholder="Otros"
                              value={values.abanddefensaotros}
                              onChange={handleChange}
                              type="text"
                            />
                            {touched.abanddefensaotros && errors.abanddefensaotros ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.abanddefensaotros}
                              </div>
                            ) : null}
                          </div>
                        </>
                      )}
                      <div className="border-t col-span-12"></div>
                      <div className="col-span-12">
                        <Select
                          label="3.4 Antes de acudir al DIF, ¿La PAM a quién informó de esta situación? (indique todos los casos que apliquen)"
                          name="abandinforma"
                          value={values.abandinforma}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.abandinforma && errors.abandinforma ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.abandinforma}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Sacerdote"
                          name="abandinformasacerdote"
                          value={values.abandinformasacerdote}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.abandinformasacerdote && errors.abandinformasacerdote ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.abandinformasacerdote}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Hospital"
                          name="abandinformahospital"
                          value={values.abandinformahospital}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.abandinformahospital && errors.abandinformahospital ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.abandinformahospital}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Bienestar Social"
                          name="abandinformabienestar"
                          value={values.abandinformabienestar}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.abandinformabienestar && errors.abandinformabienestar ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.abandinformabienestar}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Policia"
                          name="abandinformapolicia"
                          value={values.abandinformapolicia}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.abandinformapolicia && errors.abandinformapolicia ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.abandinformapolicia}
                          </div>
                        ) : null}
                      </div>
                      {
                        values.abandinforma == "No" && (
                          <>
                            <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                              <h2 className="text-base font-bold leading-7 text-gray-900">
                                Si no informó, ¿por qué no lo hizo?
                              </h2>
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Select
                                label="No sabía a quién acudir"
                                name="abandnoinformanosabe"
                                value={values.abandnoinformanosabe}
                                onChange={handleChange}
                                array={SINO}
                              />
                              {touched.abandnoinformanosabe && errors.abandnoinformanosabe ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.abandnoinformanosabe}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Select
                                label="Había informado en casos de violencia anteriores, pero no recibí apoyo"
                                name="abandnoinformanoapoyo"
                                value={values.abandnoinformanoapoyo}
                                onChange={handleChange}
                                array={SINO}
                              />
                              {touched.abandnoinformanoapoyo && errors.abandnoinformanoapoyo ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.abandnoinformanoapoyo}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Select
                                label="No quiero perjudicar a mis familiares"
                                name="abandnoinformanoperjudicar"
                                value={values.abandnoinformanoperjudicar}
                                onChange={handleChange}
                                array={SINO}
                              />
                              {touched.abandnoinformanoperjudicar && errors.abandnoinformanoperjudicar ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.abandnoinformanoperjudicar}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Select
                                label="Empeoraría la situación"
                                name="abandnoinformaempeoraria"
                                value={values.abandnoinformaempeoraria}
                                onChange={handleChange}
                                array={SINO}
                              />
                              {touched.abandnoinformaempeoraria && errors.abandnoinformaempeoraria ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.abandnoinformaempeoraria}
                                </div>
                              ) : null}
                            </div>
                          </>
                        )}
                    </>
                  )}
                </>
              )}
            </div>
            <div className="flex justify-end mt-6 gap-5">
              <button
                type="button"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-claret-800 hover:bg-claret-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => {
                  navigation("/malno");
                }}
              >
                Regresar
              </button>
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-claret-900 hover:bg-claret-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Siguiente
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
