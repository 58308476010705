/* eslint-disable eqeqeq */
import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Input from "../../components/Input";
import Select from "../../components/Select";
import { SINO } from "../../utils/selectors";
import { useNavigate } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";

export default function Vf() {
  const navigation = useNavigate();
  const [values, setvalues] = React.useState({});
  const [familia, setFamilia] = React.useState();

  React.useEffect(() => {
    const vf = localStorage.getItem("vf");
    if (vf) {
      setvalues(JSON.parse(vf));
    }
    return () => { };
  }, []);

  React.useEffect(() => {
    const esFamilia = localStorage.getItem("familiar");
    setFamilia(esFamilia);
  }, []);

  const validateSchemaVF = Yup.object({
    vf: Yup.string().required("Campo requerido"),
    vfmeses: Yup.string().when("vf", {
      is: "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vfhijos: Yup.string().when("vf", {
      is: "Si",
      then: () => Yup.number().typeError('Sólo números').required("Campo requerido"),
    }),
    vfnietos: Yup.string().when("vf", {
      is: "Si",
      then: () => Yup.number().typeError('Sólo números').required("Campo requerido"),
    }),
    vfpareja: Yup.string().when("vf", {
      is: "Si",
      then: () => Yup.number().typeError('Sólo números').required("Campo requerido"),
    }),
    vfyernonuera: Yup.string().when("vf", {
      is: "Si",
      then: () => Yup.number().typeError('Sólo números').required("Campo requerido"),
    }),
    vfotros: Yup.string().when("vf", {
      is: "Si",
      then: () => Yup.number().typeError('Sólo números').required("Campo requerido"),
    }),
    vftestigo: Yup.string().when("vf", {
      is: "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vfintoxicado:
      familia == "Si" &&
      Yup.string().when("vf", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    vfdomicilio:
      familia == "Si" &&
      Yup.string().when("vf", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    vfeconomico:
      familia == "Si" &&
      Yup.string().when("vf", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    vfdefensa:
      familia == "Si" &&
      Yup.string().when("vf", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    vfdefensahijos:
      familia == "Si" &&
      Yup.string().when("vf", {
        is: "Si",
        then: () => Yup.string().when("vfdefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    vfdefensanietos:
      familia == "Si" &&
      Yup.string().when("vf", {
        is: "Si",
        then: () => Yup.string().when("vfdefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    vfdefensapareja:
      familia == "Si" &&
      Yup.string().when("vf", {
        is: "Si",
        then: () => Yup.string().when("vfdefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    vfdefensayerno:
      familia == "Si" &&
      Yup.string().when("vf", {
        is: "Si",
        then: () => Yup.string().when("vfdefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    vfdefensaotros:
      familia == "Si" &&
      Yup.string().when("vf", {
        is: "Si",
        then: () => Yup.string().when("vfdefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    vfinforma:
      familia == "Si" &&
      Yup.string().when("vf", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    vfinformafamiliares:
      familia == "Si" &&
      Yup.string().when("vf", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    vfinformaamigos:
      familia == "Si" &&
      Yup.string().when("vf", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    vfinformasacerdote:
      familia == "Si" &&
      Yup.string().when("vf", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    vfinformahospital:
      familia == "Si" &&
      Yup.string().when("vf", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    vfinformabienestar:
      familia == "Si" &&
      Yup.string().when("vf", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    vfinformapolicia:
      familia == "Si" &&
      Yup.string().when("vf", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    vfnoinformanosabe:
      familia == "Si" && Yup.string()
        .when(["vf", "vfinforma"], {
          is: (vf, vfinforma) => vf == "Si" && vfinforma == "No",
          then: () => Yup.string().required("Campo requerido"),
        }),
    vfnoinformanoapoyo:
      familia == "Si" && Yup.string()
        .when(["vf", "vfinforma"], {
          is: (vf, vfinforma) => vf == "Si" && vfinforma == "No",
          then: () => Yup.string().required("Campo requerido"),
        }),
    vfnoinformanoperjudicar:
      familia == "Si" && Yup.string()
        .when(["vf", "vfinforma"], {
          is: (vf, vfinforma) => vf == "Si" && vfinforma == "No",
          then: () => Yup.string().required("Campo requerido"),
        }),
    vfnoinformaempeoraria:
      familia == "Si" && Yup.string()
        .when(["vf", "vfinforma"], {
          is: (vf, vfinforma) => vf == "Si" && vfinforma == "No",
          then: () => Yup.string().required("Campo requerido"),
        })
  })

  return (
    <div>
      <Formik
        initialValues={{
          vf: values?.vf || "",
          vfmeses: values?.vfmeses || "",
          vfhijos: values?.vfhijos || "",
          vfnietos: values?.vfnietos || "",
          vfpareja: values?.vfpareja || "",
          vfyernonuera: values?.vfyernonuera || "",
          vfotros: values?.vfotros || "",
          vftestigo: values?.vftestigo || "",
          vfintoxicado: values?.vfintoxicado || "",
          vfdomicilio: values?.vfdomicilio || "",
          vfeconomico: values?.vfeconomico || "",
          vfdefensa: values?.vfdefensa || "",
          vfdefensahijos: values?.vfdefensahijos || "",
          vfdefensanietos: values?.vfdefensanietos || "",
          vfdefensapareja: values?.vfdefensapareja || "",
          vfdefensayerno: values?.vfdefensayerno || "",
          vfdefensaotros: values?.vfdefensaotros || "",
          vfinforma: values?.vfinforma || "",
          vfinformafamiliares: values?.vfinformafamiliares || "",
          vfinformaamigos: values?.vfinformaamigos || "",
          vfinformasacerdote: values?.vfinformasacerdote || "",
          vfinformahospital: values?.vfinformahospital || "",
          vfinformabienestar: values?.vfinformabienestar || "",
          vfinformapolicia: values?.vfinformapolicia || "",
          vfnoinformanosabe: values?.vfnoinformanosabe || "",
          vfnoinformanoapoyo: values?.vfnoinformanoapoyo || "",
          vfnoinformanoperjudicar: values?.vfnoinformanoperjudicar || "",
          vfnoinformaempeoraria: values?.vfnoinformaempeoraria || "",
        }}
        enableReinitialize={true}
        onSubmit={(values) => {
          console.log(values)
          localStorage.setItem("vf", JSON.stringify(values));
          navigation("/malno");
        }}
        validationSchema={validateSchemaVF}
      >
        {({ values, handleChange, resetForm, setFieldValue, touched, errors }) => (
          <Form>
            <div className="grid grid-cols-1 gap-y-6 md:grid-cols-12 gap-x-6 p-2">
              <div className="col-span-12">
                <Select
                  label="1.  Recientemente...¿alguien le ha dado golpes, empujones o cachetadas?"
                  name="vf"
                  value={values.vf}
                  onChange={(e) => {
                    handleChange(e);
                    if (e.target.value == "No") {
                      const vf = e.target.value;
                      setvalues({
                        vf: vf,
                        vfmeses: "",
                        vfhijos: "",
                        vfnietos: "",
                        vfpareja: "",
                        vfyernonuera: "",
                        vfotros: "",
                        vftestigo: "",
                        vfintoxicado: "",
                        vfdomicilio: "",
                        vfeconomico: "",
                        vfdefensa: "",
                        vfdefensahijos: "",
                        vfdefensanietos: "",
                        vfdefensapareja: "",
                        vfdefensayerno: "",
                        vfdefensaotros: "",
                        vfinforma: "",
                        vfinformafamiliares: "",
                        vfinformaamigos: "",
                        vfinformasacerdote: "",
                        vfinformahospital: "",
                        vfinformabienestar: "",
                        vfinformapolicia: "",
                        vfnoinformanosabe: "",
                        vfnoinformanoapoyo: "",
                        vfnoinformanoperjudicar: "",
                        vfnoinformaempeoraria: "",
                      });
                      resetForm({
                        values: {
                          vf: vf,
                          vfmeses: "",
                          vfhijos: "",
                          vfnietos: "",
                          vfpareja: "",
                          vfyernonuera: "",
                          vfotros: "",
                          vftestigo: "",
                          vfintoxicado: "",
                          vfdomicilio: "",
                          vfeconomico: "",
                          vfdefensa: "",
                          vfdefensahijos: "",
                          vfdefensanietos: "",
                          vfdefensapareja: "",
                          vfdefensayerno: "",
                          vfdefensaotros: "",
                          vfinforma: "",
                          vfinformafamiliares: "",
                          vfinformaamigos: "",
                          vfinformasacerdote: "",
                          vfinformahospital: "",
                          vfinformabienestar: "",
                          vfinformapolicia: "",
                          vfnoinformanosabe: "",
                          vfnoinformanoapoyo: "",
                          vfnoinformanoperjudicar: "",
                          vfnoinformaempeoraria: "",
                        },
                      });
                    }
                  }}
                  array={SINO}
                />
                {touched.vf && errors.vf ? (
                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                    <ErrorIcon fontSize="small" /> {errors.vf}
                  </div>
                ) : null}
              </div>
              {values.vf == "Si" && (
                <>
                  <div className="col-span-12">
                    <Input
                      label="1.2 ¿Desde hace cuánto tiempo ocurre esto? (indica con palabras, si años, meses o días)"
                      name="vfmeses"
                      type="text"
                      placeholder="Inserta cuanto tiempo"
                      value={values.vfmeses}
                      onChange={handleChange}
                    />
                    {touched.vfmeses && errors.vfmeses ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.vfmeses}
                      </div>
                    ) : null}
                  </div>
                  <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                    <h2 className="text-base font-bold leading-7 text-gray-900">
                      ¿Quién lo hizo y cuántas veces ocurrió en el último mes?
                    </h2>
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Hijos/Hijas"
                      name="vfhijos"
                      placeholder="Hijos"
                      value={values.vfhijos}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.vfhijos && errors.vfhijos ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.vfhijos}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Nietos/Nietas"
                      name="vfnietos"
                      placeholder="Nietos"
                      value={values.vfnietos}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.vfnietos && errors.vfnietos ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.vfnietos}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Pareja"
                      name="vfpareja"
                      placeholder="Pareja"
                      value={values.vfpareja}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.vfpareja && errors.vfpareja ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.vfpareja}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Yerno/Nuera"
                      name="vfyernonuera"
                      placeholder="Yerno"
                      value={values.vfyernonuera}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.vfyernonuera && errors.vfyernonuera ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.vfyernonuera}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Otros"
                      name="vfotros"
                      placeholder="Otros"
                      value={values.vfotros}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.vfotros && errors.vfotros ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.vfotros}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Select
                      label="No sabe, pero lo atestiguó"
                      name="vftestigo"
                      value={values.vftestigo}
                      onChange={handleChange}
                      array={SINO}
                    />
                    {touched.vftestigo && errors.vftestigo ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.vftestigo}
                      </div>
                    ) : null}
                  </div>
                  {familia === "Si" && (
                    <>
                      <div className="border-t col-span-12"></div>
                      <div className="col-span-12">
                        <Select
                          label="1.3 ¿Estos episodios ocurren cuando el agresor se encuentra intoxicado (tomó alcohol, alguna droga)?"
                          name="vfintoxicado"
                          value={values.vfintoxicado}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.vfintoxicado && errors.vfintoxicado ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.vfintoxicado}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="¿El/la/los agresores comparten el domicilio que la PAM objeto de violencia?"
                          name="vfdomicilio"
                          value={values.vfdomicilio}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.vfdomicilio && errors.vfdomicilio ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.vfdomicilio}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="¿Estos episodios ocurren cuando el agresor tiene problemas económicos?"
                          name="vfeconomico"
                          value={values.vfeconomico}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.vfeconomico && errors.vfeconomico ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.vfeconomico}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-12">
                        <Select
                          label="Cuando estos episodios ocurren, ¿alguien entra en su defensa?"
                          name="vfdefensa"
                          value={values.vfdefensa}
                          onChange={(e) => {
                            handleChange(e);
                            if (e.target.value === "No") {
                              setFieldValue('vfdefensahijos', "")
                              setFieldValue('vfdefensanietos', "")
                              setFieldValue('vfdefensapareja', "")
                              setFieldValue('vfdefensayerno', "")
                              setFieldValue('vfdefensaotros', "")
                            }
                          }}
                          array={SINO}
                        />
                        {touched.vfdefensa && errors.vfdefensa ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.vfdefensa}
                          </div>
                        ) : null}
                      </div>
                      {values.vfdefensa == "Si" && (
                        <>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Hijos/Hijas"
                              name="vfdefensahijos"
                              placeholder="Hijos"
                              value={values.vfdefensahijos}
                              onChange={handleChange}
                              type="text"
                              helperText="Indique si, no o el nombre quien entra en su defensa."
                            />
                            {touched.vfdefensahijos && errors.vfdefensahijos ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.vfdefensahijos}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Nietos/Nietas"
                              name="vfdefensanietos"
                              placeholder="Nietos"
                              value={values.vfdefensanietos}
                              onChange={handleChange}
                              type="text"
                              helperText="Indique si, no o el nombre quien entra en su defensa."
                            />
                            {touched.vfdefensanietos && errors.vfdefensanietos ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.vfdefensanietos}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Pareja/esposo/esposa"
                              name="vfdefensapareja"
                              placeholder="Pareja/esposo/esposa"
                              value={values.vfdefensapareja}
                              onChange={handleChange}
                              type="text"
                              helperText="Indique si, no o el nombre quien entra en su defensa."
                            />
                            {touched.vfdefensapareja && errors.vfdefensapareja ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.vfdefensapareja}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Yerno/Nuera"
                              name="vfdefensayerno"
                              placeholder="Yerno"
                              value={values.vfdefensayerno}
                              onChange={handleChange}
                              type="text"
                              helperText="Indique si, no o el nombre quien entra en su defensa."
                            />
                            {touched.vfdefensayerno && errors.vfdefensayerno ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.vfdefensayerno}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Otras personas que no son familiares directos"
                              name="vfdefensaotros"
                              placeholder="Otros"
                              value={values.vfdefensaotros}
                              onChange={handleChange}
                              type="text"
                              helperText="Indique si, no o el nombre quien entra en su defensa."
                            />
                            {touched.vfdefensaotros && errors.vfdefensaotros ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.vfdefensaotros}
                              </div>
                            ) : null}
                          </div>
                        </>
                      )}
                      <div className="border-t col-span-12"></div>
                      <div className="col-span-12">
                        <Select
                          label="1.4 Antes de acudir al DIF, ¿La PAM informó de esta situación? (indique todos los casos que apliquen)"
                          name="vfinforma"
                          value={values.vfinforma}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.vfinforma && errors.vfinforma ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.vfinforma}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Familiares"
                          name="vfinformafamiliares"
                          value={values.vfinformafamiliares}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.vfinformafamiliares &&
                          errors.vfinformafamiliares ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" />{" "}
                            {errors.vfinformafamiliares}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Amigos"
                          name="vfinformaamigos"
                          value={values.vfinformaamigos}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.vfinformaamigos && errors.vfinformaamigos ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" />{" "}
                            {errors.vfinformaamigos}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Sacerdote"
                          name="vfinformasacerdote"
                          value={values.vfinformasacerdote}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.vfinformasacerdote &&
                          errors.vfinformasacerdote ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" />{" "}
                            {errors.vfinformasacerdote}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Hospital"
                          name="vfinformahospital"
                          value={values.vfinformahospital}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.vfinformahospital &&
                          errors.vfinformahospital ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" />{" "}
                            {errors.vfinformahospital}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Bienestar Social"
                          name="vfinformabienestar"
                          value={values.vfinformabienestar}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.vfinformabienestar &&
                          errors.vfinformabienestar ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" />{" "}
                            {errors.vfinformabienestar}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Policia"
                          name="vfinformapolicia"
                          value={values.vfinformapolicia}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.vfinformapolicia && errors.vfinformapolicia ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" />{" "}
                            {errors.vfinformapolicia}
                          </div>
                        ) : null}
                      </div>
                      {values.vfinforma === "No" && (
                        <>
                          <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                            <h2 className="text-base font-bold leading-7 text-gray-900">
                              Si no informó, ¿por qué no lo hizo?
                            </h2>
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Select
                              label="No sabía a quién acudir"
                              name="vfnoinformanosabe"
                              value={values.vfnoinformanosabe}
                              onChange={handleChange}
                              array={SINO}
                            />
                            {touched.vfnoinformanosabe &&
                              errors.vfnoinformanosabe ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" />{" "}
                                {errors.vfnoinformanosabe}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Select
                              label="Había informado en casos de violencia anteriores, pero no recibí apoyo"
                              name="vfnoinformanoapoyo"
                              value={values.vfnoinformanoapoyo}
                              onChange={handleChange}
                              array={SINO}
                            />
                            {touched.vfnoinformanoapoyo &&
                              errors.vfnoinformanoapoyo ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" />{" "}
                                {errors.vfnoinformanoapoyo}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Select
                              label="No quiero perjudicar a mis familiares"
                              name="vfnoinformanoperjudicar"
                              value={values.vfnoinformanoperjudicar}
                              onChange={handleChange}
                              array={SINO}
                            />
                            {touched.vfnoinformanoperjudicar &&
                              errors.vfnoinformanoperjudicar ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" />{" "}
                                {errors.vfnoinformanoperjudicar}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Select
                              label="Empeoraría la situación"
                              name="vfnoinformaempeoraria"
                              value={values.vfnoinformaempeoraria}
                              onChange={handleChange}
                              array={SINO}
                            />
                            {touched.vfnoinformaempeoraria &&
                              errors.vfnoinformaempeoraria ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" />{" "}
                                {errors.vfnoinformaempeoraria}
                              </div>
                            ) : null}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
            <div className="flex justify-end mt-6 gap-5">
              <button
                type="button"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-claret-800 hover:bg-claret-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => {
                  navigation("/pam");
                }}
              >
                Regresar
              </button>
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-claret-900 hover:bg-claret-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Siguiente
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
