/* eslint-disable eqeqeq */
import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Input from "../../components/Input";
import Select from "../../components/Select";
import { DATONUMEROS, SINO, Municipios, getComunidad } from "../../utils/selectors";
import { useNavigate } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";

export default function Denunciante() {
  const navigation = useNavigate();
  const [values, setvalues] = React.useState({});
  React.useEffect(() => {
    const InformacionDenuncianteParte1 = localStorage.getItem(
      "InformacionDenuncianteParte1"
    );
    if (InformacionDenuncianteParte1) {
      setvalues(JSON.parse(InformacionDenuncianteParte1));
    }
    return () => { };
  }, []);

  return (
    <div>
      <Formik
        initialValues={{
          familia: values?.familia || "",
          anonimato: values?.anonimato || "",
          nombredenunciante: values?.nombredenunciante || "",
          calle: values?.calle || "",
          numeroExterior: values?.numeroExterior || "",
          numeroInterior: values?.numeroInterior || "",
          entreCalles: values?.entreCalles || "",
          colonia: values?.colonia || "",
          manzana: values?.manzana || "",
          lote: values?.lote || "",
          codigoPostal: values?.codigoPostal || "",
          municipio: values?.municipio || "",
          comunidad: values?.comunidad || "",
          emaildenunciante: values?.emaildenunciante || "",
          teldenunciante: values?.teldenunciante || "",
          curpdenunciante: values?.curpdenunciante || "",
          primerapam: values?.primerapam || "",
          denunciaspreviaspam: values?.denunciaspreviaspam || "",
        }}
        enableReinitialize={true}
        onSubmit={(values) => {
          localStorage.setItem(
            "InformacionDenuncianteParte1",
            JSON.stringify(values)
          );

          navigation("/pam");
        }}
        validationSchema={Yup.object({
          familia: Yup.string().required("Requerido"),
          anonimato: Yup.string().when("familia", {
            is: "Si",
            then: () => Yup.string().required("Requerido"),
          }),
          calle: Yup.string().when("anonimato", {
            is: "No",
            then: () => Yup.string().required("Requerido"),
          }),
          nombredenunciante: Yup.string().when("anonimato", {
            is: "No",
            then: () => Yup.string().required("Requerido"),
          }),
          numeroExterior: Yup.string().when("anonimato", {
            is: "No",
            then: () => Yup.string().required("Requerido"),
          }),
          // numeroInterior: Yup.string().when("anonimato", {
          //   is: "0",
          //   then: () => Yup.string().required("Requerido"),
          // }),
          // entreCalles: Yup.string().when("anonimato", {
          //   is: "0",
          //   then: () => Yup.string().required("Requerido"),
          // }),
          // colonia: Yup.string().when("anonimato", {
          //   is: "0",
          //   then: () => Yup.string().required("Requerido"),
          // }),
          // manzana: Yup.string().when("anonimato", {
          //   is: "0",
          //   then: () => Yup.string().required("Requerido"),
          // }),
          // lote: Yup.string().when("anonimato", {
          //   is: "0",
          //   then: () => Yup.string().required("Requerido"),
          // }),
          // codigoPostal: Yup.string().when("anonimato", {
          //   is: "No",
          //   then: () =>
          //     Yup.string()
          //       .required("Requerido")
          //       .matches(
          //         /^[0-9]{5}$/,
          //         "El código postal debe tener 5 dígitos y solo números"
          //       ),
          // }),
          municipio: Yup.string().when("anonimato", {
            is: "No",
            then: () => Yup.string().required("Requerido"),
          }),
          comunidad: Yup.string().when("anonimato", {
            is: "No",
            then: () => Yup.string().required("Requerido"),
          }),
          // emaildenunciante: Yup.string().when("anonimato", {
          //   is: "0",
          //   then: () =>
          //     Yup.string()
          //       .email("Inserta un correo electrónico valido")
          //       .required("Requerido"),
          // }),
          // teldenunciante: Yup.string().when("anonimato", {
          //   is: "0",
          //   then: () => Yup.string()
          //     .required("Requerido")
          //     .matches(
          //       /^[0-9]{10}$/,
          //       "Inserta solo 10 números"
          //     ),
          // }),
          // curpdenunciante: Yup.string().when("anonimato", {
          //   is: "0",
          //   then: () => Yup.string().min(18, 'El CURP debe tener como mínimo 18 caracteres').max(18, 'El CURP debe tener como máximo 18 caracteres').required("Requerido"),
          // }),
        })}
      >
        {({ values, handleChange, touched, errors, resetForm }) => (
          <Form>
            <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
              <div className="sm:col-span-12">
                <Select
                  label="¿La persona que denuncia es la victima o familiar de la víctima?"
                  name="familia"
                  value={values.familia}
                  onChange={(e) => {
                    console.log(e.target.value)
                    localStorage.clear();
                    localStorage.setItem("familiar", e.target.value);
                    handleChange(e);
                    if (e.target.value == "No") {
                      setvalues({});
                      resetForm();
                      const familia = e.target.value;
                      setvalues({
                        familia,
                      });
                      localStorage.clear();
                      localStorage.setItem("familiar", e.target.value);
                    }
                  }}
                  array={SINO}
                />
                {touched.familia && errors.familia ? (
                  <div className="py-1 px-1 bg-red-500 rounded-md shadow text-white text-sm">
                    <ErrorIcon fontSize="small" /> {errors.familia}
                  </div>
                ) : null}
              </div>
              {values.familia == "Si" && (
                <div className="sm:col-span-12">
                  <Select
                    label="¿Desea hacer este reporte de manera anonima?"
                    name="anonimato"
                    value={values.anonimato}
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.value === "Si") {
                        const anonimato = e.target.value;
                        const familia = values.familia;
                        resetForm();
                        setvalues({
                          familia,
                          anonimato,
                        });
                      }
                    }}
                    array={SINO}
                  />
                  {touched.anonimato && errors.anonimato ? (
                    <div className="py-1 px-1 bg-red-500 rounded-md shadow text-white text-sm">
                      <ErrorIcon fontSize="small" /> {errors.anonimato}
                    </div>
                  ) : null}
                </div>
              )}
              <div className="sm:col-span-12">
                <Select
                  label="¿Esta es la primera denuncia que se relaciona con esta pam?"
                  name="primerapam"
                  value={values.primerapam}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  array={SINO}
                />
                {touched.primerapam && errors.primerapam ? (
                  <div className="py-1 px-1 bg-red-500 rounded-md shadow text-white text-sm">
                    <ErrorIcon fontSize="small" /> {errors.primerapam}
                  </div>
                ) : null}
              </div>
              {values.primerapam === "Si" && (
                <div className="sm:col-span-12">
                  <Select
                    label="¿Cuantas denuncias previas a esta pam?"
                    name="denunciaspreviaspam"
                    value={values.denunciaspreviaspam}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    array={DATONUMEROS}
                  />
                  {touched.denunciaspreviaspam && errors.denunciaspreviaspam ? (
                    <div className="py-1 px-1 bg-red-500 rounded-md shadow text-white text-sm">
                      <ErrorIcon fontSize="small" /> {errors.denunciaspreviaspam}
                    </div>
                  ) : null}
                </div>
              )}
              {values.familia === "Si" && values.anonimato === "No" && (
                <>
                  <div className="sm:col-span-6">
                    <Input
                      label="Nombre y apellidos"
                      name="nombredenunciante"
                      type="text"
                      placeholder="En caso de ser anonimo, escribe la palabra Anonimo"
                      value={values.nombredenunciante}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <small className="text-xs text-gray-400">
                      En caso de ser anonimo, escribe la palabra "Anonimo"
                    </small>
                    {touched.nombredenunciante && errors.nombredenunciante ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" />{" "}
                        {errors.nombredenunciante}
                      </div>
                    ) : null}
                  </div>
                  <div className="sm:col-span-6">
                    <Input
                      label="Calle"
                      name="calle"
                      type="text"
                      placeholder="Calle del domicilio"
                      value={values.calle}
                      onChange={handleChange}
                    />
                    {touched.calle && errors.calle ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.calle}
                      </div>
                    ) : null}
                  </div>
                  <div className="sm:col-span-6">
                    <Input
                      label="Número exterior"
                      name="numeroExterior"
                      type="text"
                      placeholder="Número exterior"
                      value={values.numeroExterior}
                      onChange={handleChange}
                    />
                    {touched.numeroExterior && errors.numeroExterior ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.numeroExterior}
                      </div>
                    ) : null}
                  </div>
                  <div className="sm:col-span-6">
                    <Input
                      label="Número interior"
                      name="numeroInterior"
                      type="text"
                      placeholder="Número interior"
                      value={values.numeroInterior}
                      onChange={handleChange}
                    />
                    {touched.numeroInterior && errors.numeroInterior ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.numeroInterior}
                      </div>
                    ) : null}
                  </div>
                  <div className="sm:col-span-6">
                    <Input
                      label="Entre calles"
                      name="entreCalles"
                      type="text"
                      placeholder="Entre que calles"
                      value={values.entreCalles}
                      onChange={handleChange}
                    />
                    {touched.entreCalles && errors.entreCalles ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.entreCalles}
                      </div>
                    ) : null}
                  </div>
                  <div className="sm:col-span-6">
                    <Input
                      label="Colonia"
                      name="colonia"
                      type="text"
                      placeholder="Colonia"
                      value={values.colonia}
                      onChange={handleChange}
                    />
                    {touched.colonia && errors.colonia ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.colonia}
                      </div>
                    ) : null}
                  </div>
                  <div className="sm:col-span-6">
                    <Input
                      label="Manzana"
                      name="manzana"
                      type="text"
                      placeholder="Manzana"
                      value={values.manzana}
                      onChange={handleChange}
                    />
                    {touched.manzana && errors.manzana ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.manzana}
                      </div>
                    ) : null}
                  </div>
                  <div className="sm:col-span-6">
                    <Input
                      label="Lote"
                      name="lote"
                      type="text"
                      placeholder="Lote"
                      value={values.lote}
                      onChange={handleChange}
                    />
                    {touched.lote && errors.lote ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.lote}
                      </div>
                    ) : null}
                  </div>
                  <div className="sm:col-span-6">
                    <Input
                      label="Código postal"
                      name="codigoPostal"
                      type="text"
                      maxLength="5"
                      placeholder="Código postal"
                      value={values.codigoPostal}
                      onChange={handleChange}
                    />
                    {touched.codigoPostal && errors.codigoPostal ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.codigoPostal}
                      </div>
                    ) : null}
                  </div>
                  <div className="sm:col-span-6">
                    <Select
                      label="Municipio"
                      name="municipio"
                      value={values.municipio}
                      onChange={handleChange}
                      array={Municipios}
                    />
                    {touched.municipio && errors.municipio ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.municipio}
                      </div>
                    ) : null}
                  </div>
                  <div className="sm:col-span-6">
                    <Select
                      label="Comunidad"
                      name="comunidad"
                      placeholder="comunidad"
                      value={values.comunidad}
                      onChange={handleChange}
                      array={getComunidad(values.municipio)}
                    />
                    {touched.comunidad && errors.comunidad ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.comunidad}
                      </div>
                    ) : null}
                  </div>
                  <div className="sm:col-span-6">
                    <Input
                      label="Email del denunciante"
                      name="emaildenunciante"
                      type="text"
                      placeholder="Email del denunciante"
                      value={values.emaildenunciante}
                      onChange={handleChange}
                    />
                    {touched.emaildenunciante && errors.emaildenunciante ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.emaildenunciante}
                      </div>
                    ) : null}
                  </div>
                  <div className="sm:col-span-6">
                    <Input
                      label="Teléfono del denunciante"
                      name="teldenunciante"
                      type="text"
                      maxLength="10"
                      placeholder="Teléfono del denunciante"
                      value={values.teldenunciante}
                      onChange={handleChange}
                    />
                    {touched.teldenunciante && errors.teldenunciante ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.teldenunciante}
                      </div>
                    ) : null}
                  </div>
                  <div className="sm:col-span-6">
                    <Input
                      label="CURP del denunciante"
                      name="curpdenunciante"
                      type="text"
                      placeholder="CURP del denunciante"
                      maxLength="18"
                      value={values.curpdenunciante}
                      onChange={handleChange}
                    />
                    {touched.curpdenunciante && errors.curpdenunciante ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.curpdenunciante}
                      </div>
                    ) : null}
                  </div>
                </>
              )}
            </div>
            <div className="flex justify-end mt-6">
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-claret-900 hover:bg-claret-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Siguiente
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div >
  );
}
