import React from "react";
import { useNavigate } from "react-router-dom";
export default function PageHeader() {
  const navigate = useNavigate();
  return (
    <div>
      <div>
        <div
          className="h-full w-full object-cover pt-5 pb-5"
          style={{
            background: "#621132",
          }}
        >
          <div className="max-w-7xl mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
              <div>
                <img
                  src="/img/Amexcid.png"
                  alt="Logo Amexicd"
                  className="w-1/2 mx-auto p-2 lg:w-1/3 lg:p-0"
                />
              </div>
              <div>
                <img
                  src="/img/FondoMexicoUruguay.png"
                  alt="Logo Fondo México Uruguay"
                  className="w-1/2 mx-auto p-2 lg:w-1/3 lg:p-0"
                />
              </div>
              <div className="lg:col-span-2">
                <img
                  src="/img/UruguayPresidencia.png"
                  alt="Logo Uruguay Presidencia"
                  className="w-1/2 mx-auto p-2 lg:w-1/3 lg:p-0"
                />
              </div>
            </div>
            <div className="max-w-3xl mx-auto text-center mt-10">
              <h1 className="text-2xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-4xl">
                <span className="block">
                  Cuestionario Detección de Violencia
                </span>
              </h1>
            </div>
          </div>
        </div>
        {/* button empezar nueva encuesta */}
        <div className="max-w-7xl mx-auto text-center mt-4 flex justify-end">
          <button
            type="button"
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
            className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-claret-900 hover:bg-claret-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Empezar nueva encuesta
          </button>
        </div>
      </div>
    </div>
  );
}
