import React from "react";

export default function Input({
  label,
  placeholder,
  type,
  name,
  value,
  onChange,
  maxLength,
  helperText = null,
}) {
  return (
    <div className="py-1">
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <div className="mt-2">
        <input
          type={type}
          name={name}
          id={name}
          placeholder={placeholder}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#621132] sm:text-sm sm:leading-6"
          value={value}
          onChange={onChange}
          maxLength={maxLength}
        />
        {
          helperText && (
            <p className="mt-1 text-xs text-gray-500" id={name}>
              {helperText}
            </p>
          )
        }
      </div>
    </div>
  );
}
