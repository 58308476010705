/* eslint-disable no-mixed-operators */
/* eslint-disable eqeqeq */
import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Input from "../../components/Input";
import Select from "../../components/Select";
import {
  HombreMujer,
  estadoCivil,
  heredo,
  herencia,
  ultimoGrado,
  vivienda,
  SINO,
  SINONOSABE,
  Municipios,
  getComunidad,
  viveCon,
  acudeBanco,
  alzanza,
  atencionMedica,
  ayuda,
  dispone65,
  horasTrabajo,
  inscritoPrograma,
  manejoCuentas,
  remuneracion,
  seguridadSocial,
  usaTarjeta,
} from "../../utils/selectors";
import { useNavigate } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";

export default function Pam() {
  const navigation = useNavigate();
  const [values, setvalues] = React.useState({});
  const [familia, setFamilia] = React.useState();

  React.useEffect(() => {
    const pam = localStorage.getItem("pam");
    if (pam) {
      setvalues(JSON.parse(pam));
    }
    return () => { };
  }, []);

  useEffect(() => {
    const esFamilia = localStorage.getItem("familiar");
    setFamilia(esFamilia);
  }, []);

  const validateSchema = Yup.object(
    familia == "No"
      ? {
        pamviolencia: Yup.string().required("Requerido"),
        // callePam: Yup.string().required("Requerido"),
        // numeroExteriorPam: Yup.string().required("Requerido"),
        // numeroInteriorPam: Yup.string().required("Requerido"),
        // entreCallesPam: Yup.string().required("Requerido"),
        // coloniaPam: Yup.string().required("Requerido"),
        // manzanaPam: Yup.string().required("Requerido"),
        // lotePam: Yup.string().required("Requerido"),
        // codigoPostalPam: Yup.string()
        //   .required("Requerido")
        //   .matches(
        //     /^[0-9]{5}$/,
        //     "El código postal debe tener 5 dígitos y solo números"
        //   ),
        municipioPam: Yup.string().required("Requerido"),
        comunidadPam: Yup.string().required("Requerido"),
        sexo: Yup.string().required("Requerido"),
        muletasilla: Yup.string().required("Requerido"),
        dismotriz: Yup.string().required("Requerido"),
        disauditiva: Yup.string().required("Requerido"),
        disvisual: Yup.string().required("Requerido"),
        dishabla: Yup.string().required("Requerido"),
        disotra: Yup.string().required("Requerido"),
      }
      : {
        pamviolencia: Yup.string().required("Requerido"),
        // callePam: Yup.string().required("Requerido"),
        // numeroExteriorPam: Yup.string().required("Requerido"),
        // numeroInteriorPam: Yup.string().required("Requerido"),
        // entreCallesPam: Yup.string().required("Requerido"),
        // coloniaPam: Yup.string().required("Requerido"),
        // manzanaPam: Yup.string().required("Requerido"),
        // lotePam: Yup.string().required("Requerido"),
        // codigoPostalPam: Yup.string()
        //   .required("Requerido")
        //   .matches(
        //     /^[0-9]{5}$/,
        //     "El código postal debe tener 5 dígitos y solo números"
        //   ),
        municipioPam: Yup.string().required("Requerido"),
        comunidadPam: Yup.string().required("Requerido"),
        // emailpamviolencia: Yup.string()
        //   .email("Inserta un correo electrónico valido")
        //   .required("Requerido"),
        // telpamviolencia: Yup.string()
        //   .required("Requerido")
        //   .matches(
        //     /^[0-9]{10}$/,
        //     "Inserta solo 10 números"
        //   ),
        // curppamviolencia: Yup.string().min(18, 'El CURP debe tener como mínimo 18 caracteres').max(18, 'El CURP debe tener como máximo 18 caracteres').required("Requerido"),
        sexo: Yup.string().required("Requerido"),
        muletasilla: Yup.string().required("Requerido"),
        edadsabe: Yup.string().required("Requerido"),
        edad: Yup.string().when('edadsabe', {
          is: "Si",
          then: () => Yup.number().typeError('Sólo números').required("Requerido").min(60, "Solo mayores a 60")
        }),
        edocivil: Yup.string().required("Requerido"),
        ultimoescolar: Yup.string().required("Requerido"),
        leer: Yup.string().when("ultimoescolar", {
          is: "Ninguno",
          then: () => Yup.string().required("Requerido"),
        }),
        escribir: Yup.string().when("ultimoescolar", {
          is: "Ninguno",
          then: () => Yup.string().required("Requerido"),
        }),
        indigena: Yup.string().required("Requerido"),
        viveen: Yup.string().required("Requerido"),
        herenciacasa: Yup.string().when('viveen', {
          is: "Propio",
          then: () => Yup.string().required("Requerido"),
        }),
        heredo: Yup.string().required("Requerido"),
        vivecon: Yup.string().required("Requerido"),
        personas: Yup.number().typeError('Sólo números').required("Requerido"),
        menores: Yup.number().typeError('Sólo números').when('personas', {
          is: (personas) => personas >= 2,
          then: () => Yup.string().required("Requerido"),
        }),
        aliadulto1: Yup.string().required("Requerido"),
        aliadulto2: Yup.string().required("Requerido"),
        aliadulto3: Yup.string().required("Requerido"),
        aliadulto4: Yup.string().required("Requerido"),
        aliadulto5: Yup.string().required("Requerido"),
        aliadulto6: Yup.string().required("Requerido"),
        alimenor1: Yup.string().when('menores', {
          is: (menores) => menores > 0,
          then: () => Yup.string().required("Requerido"),
        }),
        alimenor2: Yup.string().when('menores', {
          is: (menores) => menores > 0,
          then: () => Yup.string().required("Requerido"),
        }),
        alimenor3: Yup.string().when('menores', {
          is: (menores) => menores > 0,
          then: () => Yup.string().required("Requerido"),
        }),
        alimenor4: Yup.string().when('menores', {
          is: (menores) => menores > 0,
          then: () => Yup.string().required("Requerido"),
        }),
        alimenor5: Yup.string().when('menores', {
          is: (menores) => menores > 0,
          then: () => Yup.string().required("Requerido"),
        }),
        alimenor6: Yup.string().when('menores', {
          is: (menores) => menores > 0,
          then: () => Yup.string().required("Requerido"),
        }),
        seguridadsocial: Yup.string().required("Requerido"),
        atnmedica: Yup.string().required("Requerido"),
        trabaja: Yup.string().required("Requerido"),
        horastrabajo: Yup.string().when("trabaja", {
          is: "Si",
          then: () => Yup.string().required("Requerido"),
        }),
        remuneracion: Yup.string().when("trabaja", {
          is: "Si",
          then: () => Yup.string().required("Requerido"),
        }),
        soloespecie: Yup.string().when("trabaja", {
          is: "Si",
          then: () => Yup.string().required("Requerido"),
        }),
        otrosingresos: Yup.string().required("Requerido"),
        ahorro: Yup.string().required("Requerido"),
        deuda: Yup.string().required("Requerido"),
        necapoyoeconomico: Yup.string().required("Requerido"),
        recibeapoyoeconomico: Yup.string().required("Requerido"),
        cantidadapoyoeconomico: Yup.string().when("recibeapoyoeconomico", {
          is: "Si",
          then: () => Yup.string().required("Requerido"),
        }),
        necapoyoespecie: Yup.string().required("Requerido"),
        recibeapoyoespecie: Yup.string().required("Requerido"),
        apoyoespeciealimentos: Yup.string().when("recibeapoyoespecie", {
          is: "Si",
          then: () => Yup.string().required("Requerido"),
        }),
        apoyoespeciemedicinas: Yup.string().when("recibeapoyoespecie", {
          is: "Si",
          then: () => Yup.string().required("Requerido"),
        }),
        apoyoespecietraslados: Yup.string().when("recibeapoyoespecie", {
          is: "Si",
          then: () => Yup.string().required("Requerido"),
        }),
        apoyoespecietiempo: Yup.string().when("recibeapoyoespecie", {
          is: "Si",
          then: () => Yup.string().required("Requerido"),
        }),
        apoyoespecieotro: Yup.string().when("recibeapoyoespecie", {
          is: "Si",
          then: () => Yup.string().required("Requerido"),
        }),
        apoyasushijos: Yup.string().required("Requerido"),
        cuidonietos: Yup.string().when("apoyasushijos", {
          is: "Si",
          then: () => Yup.string().required("Requerido"),
        }),
        llevoalaescuela: Yup.string().when("apoyasushijos", {
          is: "Si",
          then: () => Yup.string().required("Requerido"),
        }),
        doydinero: Yup.string().when("apoyasushijos", {
          is: "Si",
          then: () => Yup.string().required("Requerido"),
        }),
        vivenenmicasa: Yup.string().when("apoyasushijos", {
          is: "Si",
          then: () => Yup.string().required("Requerido"),
        }),
        pagoservicios: Yup.string().when("apoyasushijos", {
          is: "Si",
          then: () => Yup.string().required("Requerido"),
        }),
        ayudoenlacasa: Yup.string().when("apoyasushijos", {
          is: "Si",
          then: () => Yup.string().required("Requerido"),
        }),
        alcanza: Yup.string().required("Requerido"),
        inscritoprograma65ymas: Yup.string().required("Requerido"),
        usatarjeta65ymas: Yup.string().when("inscritoprograma65ymas", {
          is: "Si, sin tarjeta",
          then: () => Yup.string().required("Requerido"),
        }),
        disponerecursos65ymas: Yup.string().when(
          "inscritoprograma65ymas",
          {
            is: "Si, sin tarjeta",
            then: () => Yup.string().required("Requerido"),
          }
        ),
        cuentabancaria: Yup.string().required("Requerido"),
        manejotrascuentas: Yup.string().when("cuentabancaria", {
          is: "Si",
          then: () => Yup.string().required("Requerido"),
        }),
        atmyayuda: Yup.string().when("cuentabancaria", {
          is: "Si",
          then: () => Yup.string().required("Requerido"),
        }),
        acudebancoayuda: Yup.string().when("cuentabancaria", {
          is: "Si",
          then: () => Yup.string().required("Requerido"),
        }),
        asma: Yup.string().required("Requerido"),
        colesterol: Yup.string().required("Requerido"),
        ecv: Yup.string().required("Requerido"),
        cardio: Yup.string().required("Requerido"),
        cancer: Yup.string().required("Requerido"),
        epoc: Yup.string().required("Requerido"),
        diabetes: Yup.string().required("Requerido"),
        hipertension: Yup.string().required("Requerido"),
        osteoporosis: Yup.string().required("Requerido"),
        artrosis: Yup.string().required("Requerido"),
        renal: Yup.string().required("Requerido"),
        dismotriz: Yup.string().required("Requerido"),
        disauditiva: Yup.string().required("Requerido"),
        disvisual: Yup.string().required("Requerido"),
        dishabla: Yup.string().required("Requerido"),
        disotra: Yup.string().required("Requerido"),
      }
  )

  const runValidations = () => {
    validateSchema
      .validate(values, { abortEarly: false })
      .then((responseData) => {
        console.log("no validation errors");
        console.log(responseData);
      })
      .catch((err) => {
        err.inner.forEach(error => {
          console.log(error.path, error.message)
        })
        throw err
      })
  };

  return (
    <div>
      <Formik
        initialValues={{
          pamviolencia: values?.pamviolencia || "",
          callePam: values?.callePam || "",
          numeroExteriorPam: values?.numeroExteriorPam || "",
          numeroInteriorPam: values?.numeroInteriorPam || "",
          entreCallesPam: values?.entreCallesPam || "",
          coloniaPam: values?.coloniaPam || "",
          manzanaPam: values?.manzanaPam || "",
          lotePam: values?.lotePam || "",
          codigoPostalPam: values?.codigoPostalPam || "",
          municipioPam: values?.municipioPam || "",
          comunidadPam: values?.comunidadPam || "",
          emailpamviolencia: values?.emailpamviolencia || "",
          telpamviolencia: values?.telpamviolencia || "",
          curppamviolencia: values?.curppamviolencia || "",
          sexo: values?.sexo || "",
          muletasilla: values?.muletasilla || "",
          edadsabe: values?.edadsabe || "",
          edad: values?.edad || "",
          edocivil: values?.edocivil || "",
          ultimoescolar: values?.ultimoescolar || "",
          leer: values?.leer || "",
          escribir: values?.escribir || "",
          indigena: values?.indigena || "",
          viveen: values?.viveen || "",
          herenciacasa: values?.herenciacasa || "",
          heredo: values?.heredo || "",
          vivecon: values?.vivecon || "",
          personas: values?.personas || "",
          menores: values?.menores || "",
          aliadulto1: values?.aliadulto1 || "",
          aliadulto2: values?.aliadulto2 || "",
          aliadulto3: values?.aliadulto3 || "",
          aliadulto4: values?.aliadulto4 || "",
          aliadulto5: values?.aliadulto5 || "",
          aliadulto6: values?.aliadulto6 || "",
          alimenor1: values?.alimenor1 || "",
          alimenor2: values?.alimenor2 || "",
          alimenor3: values?.alimenor3 || "",
          alimenor4: values?.alimenor4 || "",
          alimenor5: values?.alimenor5 || "",
          alimenor6: values?.alimenor6 || "",
          seguridadsocial: values?.seguridadsocial || "",
          atnmedica: values?.atnmedica || "",
          trabaja: values?.trabaja || "",
          horastrabajo: values?.horastrabajo || "",
          remuneracion: values?.remuneracion || "",
          soloespecie: values?.soloespecie || "",
          otrosingresos: values?.otrosingresos || "",
          ahorro: values?.ahorro || "",
          deuda: values?.deuda || "",
          necapoyoeconomico: values?.necapoyoeconomico || "",
          recibeapoyoeconomico: values?.recibeapoyoeconomico || "",
          cantidadapoyoeconomico: values?.cantidadapoyoeconomico || "",
          necapoyoespecie: values?.necapoyoespecie || "",
          recibeapoyoespecie: values?.recibeapoyoespecie || "",
          apoyoespeciealimentos: values?.apoyoespeciealimentos || "",
          apoyoespeciemedicinas: values?.apoyoespeciemedicinas || "",
          apoyoespecietraslados: values?.apoyoespecietraslados || "",
          apoyoespecietiempo: values?.apoyoespecietiempo || "",
          apoyoespecieotro: values?.apoyoespecieotro || "",
          apoyasushijos: values?.apoyasushijos || "",
          cuidonietos: values?.cuidonietos || "",
          llevoalaescuela: values?.llevoalaescuela || "",
          doydinero: values?.doydinero || "",
          vivenenmicasa: values?.vivenenmicasa || "",
          pagoservicios: values?.pagoservicios || "",
          ayudoenlacasa: values?.ayudoenlacasa || "",
          alcanza: values?.alcanza || "",
          inscritoprograma65ymas: values?.inscritoprograma65ymas || "",
          usatarjeta65ymas: values?.usatarjeta65ymas || "",
          disponerecursos65ymas: values?.disponerecursos65ymas || "",
          cuentabancaria: values?.cuentabancaria || "",
          manejotrascuentas: values?.manejotrascuentas || "",
          atmyayuda: values?.atmyayuda || "",
          acudebancoayuda: values?.acudebancoayuda || "",
          asma: values?.asma || "",
          colesterol: values?.colesterol || "",
          ecv: values?.ecv || "",
          cardio: values?.cardio || "",
          hipertension: values?.hipertension || "",
          cancer: values?.cancer || "",
          epoc: values?.epoc || "",
          diabetes: values?.diabetes || "",
          osteoporosis: values?.osteoporosis || "",
          artrosis: values?.artrosis || "",
          renal: values?.renal || "",
          dismotriz: values?.dismotriz || "",
          disauditiva: values?.disauditiva || "",
          disvisual: values?.disvisual || "",
          dishabla: values?.dishabla || "",
          disotra: values?.disotra || "",
        }}
        enableReinitialize={true}
        onSubmit={(values) => {
          console.log(JSON.stringify(values))
          localStorage.setItem("pam", JSON.stringify(values));
          navigation("/vf");
        }}
        validationSchema={validateSchema}
      >
        {({ values, handleChange, touched, errors, setFieldValue }) => (
          <Form>
            <div className="grid grid-cols-1 gap-y-6 md:grid-cols-12 gap-x-6 p-2">
              <div>
                <button onClick={runValidations}>run validations</button>
              </div>
              <div className="col-span-12">
                <Input
                  label="Nombre y apellidos o conocido como, de la PAM objeto de violencia"
                  name="pamviolencia"
                  type="text"
                  placeholder="Nombre y apellidos de la PAM objeto de violencia"
                  value={values.pamviolencia}
                  onChange={handleChange}
                />
                {touched.pamviolencia && errors.pamviolencia ? (
                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                    <ErrorIcon fontSize="small" /> {errors.pamviolencia}
                  </div>
                ) : null}
              </div>
              <div className="border-b border-t pt-6 border-gray-900/10 pb-6 col-span-12">
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  Dirección
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  Dirección de la PAM objeto de violencia
                </p>
              </div>
              <div className="col-span-12 lg:col-span-4">
                <Input
                  label="Calle"
                  name="callePam"
                  type="text"
                  placeholder="Calle del domicilio"
                  value={values.callePam}
                  onChange={handleChange}
                />
                {touched.callePam && errors.callePam ? (
                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                    <ErrorIcon fontSize="small" /> {errors.callePam}
                  </div>
                ) : null}
              </div>
              <div className="col-span-12 lg:col-span-4">
                <Input
                  label="Número exterior"
                  name="numeroExteriorPam"
                  type="text"
                  placeholder="Número exterior"
                  value={values.numeroExteriorPam}
                  onChange={handleChange}
                />
                {touched.numeroExteriorPam && errors.numeroExteriorPam ? (
                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                    <ErrorIcon fontSize="small" /> {errors.numeroExteriorPam}
                  </div>
                ) : null}
              </div>
              <div className="col-span-12 lg:col-span-4">
                <Input
                  label="Número interior"
                  name="numeroInteriorPam"
                  type="text"
                  placeholder="Número interior"
                  value={values.numeroInteriorPam}
                  onChange={handleChange}
                />
                {touched.numeroInteriorPam && errors.numeroInteriorPam ? (
                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                    <ErrorIcon fontSize="small" /> {errors.numeroInteriorPam}
                  </div>
                ) : null}
              </div>
              <div className="col-span-12 lg:col-span-4">
                <Input
                  label="Entre calles"
                  name="entreCallesPam"
                  type="text"
                  placeholder="Entre que calles"
                  value={values.entreCallesPam}
                  onChange={handleChange}
                />
                {touched.entreCallesPam && errors.entreCallesPam ? (
                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                    <ErrorIcon fontSize="small" /> {errors.entreCallesPam}
                  </div>
                ) : null}
              </div>
              <div className="col-span-12 lg:col-span-4">
                <Input
                  label="Colonia"
                  name="coloniaPam"
                  type="text"
                  placeholder="Colonia"
                  value={values.coloniaPam}
                  onChange={handleChange}
                />
                {touched.coloniaPam && errors.coloniaPam ? (
                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                    <ErrorIcon fontSize="small" /> {errors.coloniaPam}
                  </div>
                ) : null}
              </div>
              <div className="col-span-12 lg:col-span-4">
                <Input
                  label="Manzana"
                  name="manzanaPam"
                  type="text"
                  placeholder="Manzana"
                  value={values.manzanaPam}
                  onChange={handleChange}
                />
                {touched.manzanaPam && errors.manzanaPam ? (
                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                    <ErrorIcon fontSize="small" /> {errors.manzanaPam}
                  </div>
                ) : null}
              </div>
              <div className="col-span-12 sm:col-span-3">
                <Input
                  label="Lote"
                  name="lotePam"
                  type="text"
                  placeholder="Lote"
                  value={values.lote}
                  onChange={handleChange}
                />
                {touched.lotePam && errors.lotePam ? (
                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                    <ErrorIcon fontSize="small" /> {errors.lotePam}
                  </div>
                ) : null}
              </div>
              <div className="col-span-12 sm:col-span-3">
                <Input
                  label="Código postal"
                  name="codigoPostalPam"
                  type="text"
                  maxLength="5"
                  placeholder="Código postal"
                  value={values.codigoPostalPam}
                  onChange={handleChange}
                />
                {touched.codigoPostalPam && errors.codigoPostalPam ? (
                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                    <ErrorIcon fontSize="small" /> {errors.codigoPostalPam}
                  </div>
                ) : null}
              </div>
              <div className="col-span-12 lg:col-span-3">
                <Select
                  label="Municipio"
                  name="municipioPam"
                  value={values.municipioPam}
                  onChange={handleChange}
                  array={Municipios}
                />
                {touched.municipioPam && errors.municipioPam ? (
                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                    <ErrorIcon fontSize="small" /> {errors.municipioPam}
                  </div>
                ) : null}
              </div>
              <div className="col-span-12 lg:col-span-3">
                <Select
                  label="Comunidad"
                  name="comunidadPam"
                  placeholder="comunidad"
                  value={values.comunidadPam}
                  onChange={handleChange}
                  array={getComunidad(values.municipioPam)}
                />
                {touched.comunidadPam && errors.comunidadPam ? (
                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                    <ErrorIcon fontSize="small" /> {errors.comunidadPam}
                  </div>
                ) : null}
              </div>
              {familia === "Si" && (
                <>
                  <div className="col-span-12 border-t"></div>
                  <div className="col-span-12 md:col-span-6">
                    <Input
                      label="Correo electrónico de la PAM objeto de violencia"
                      name="emailpamviolencia"
                      type="text"
                      placeholder="Correo electrónico de la PAM objeto de violencia"
                      value={values.emailpamviolencia}
                      onChange={handleChange}
                    />
                    {touched.emailpamviolencia && errors.emailpamviolencia ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" />{" "}
                        {errors.emailpamviolencia}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <Input
                      label="Teléfono de la PAM objeto de violencia"
                      name="telpamviolencia"
                      type="text"
                      maxLength="10"
                      placeholder="Teléfono de la PAM objeto de violencia"
                      value={values.telpamviolencia}
                      onChange={handleChange}
                    />
                    {touched.telpamviolencia && errors.telpamviolencia ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.telpamviolencia}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <Input
                      label="CURP de la PAM objeto de violencia"
                      name="curppamviolencia"
                      type="text"
                      maxLength="18"
                      placeholder="CURP de la PAM objeto de violencia"
                      value={values.curppamviolencia}
                      onChange={handleChange}
                    />
                    {touched.curppamviolencia && errors.curppamviolencia ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.curppamviolencia}
                      </div>
                    ) : null}
                  </div>
                </>
              )}
              <div className="col-span-12 md:col-span-6">
                <Select
                  label="Sexo de la PAM objeto de violencia"
                  name="sexo"
                  value={values.sexo}
                  onChange={handleChange}
                  array={HombreMujer}
                />
                {touched.sexo && errors.sexo ? (
                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                    <ErrorIcon fontSize="small" /> {errors.sexo}
                  </div>
                ) : null}
              </div>
              <div className="col-span-12 lg:col-span-6">
                <Select
                  label="La PAM usa muletas, bastón o silla de ruedas"
                  name="muletasilla"
                  value={values.muletasilla}
                  onChange={handleChange}
                  array={SINONOSABE}
                />
                {touched.muletasilla && errors.muletasilla ? (
                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                    <ErrorIcon fontSize="small" /> {errors.muletasilla}
                  </div>
                ) : null}
              </div>
              {familia === "Si" && (
                <>
                  <div className="col-span-12 md:col-span-6">
                    <Select
                      label="¿Conoce la edad de la PAM?"
                      name="edadsabe"
                      type="text"
                      placeholder="Edad"
                      value={values.edadsabe}
                      array={SINONOSABE}
                      onChange={(e) => {
                        handleChange(e)
                        if (e.target.name === 'edadsabe') {
                          setFieldValue('edad', '');
                        }
                      }}
                    />
                    {touched.edadsabe && errors.edadsabe ? (
                      <div className="py-1 px-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.edadsabe}
                      </div>
                    ) : null}
                  </div>
                  {values.edadsabe == "Si" && values.edadsabe != "" && (
                    <>
                      <div className="border-t col-span-12"></div>
                      <div className="col-span-12 md:col-span-12">
                        <Input
                          label="Edad de la PAM"
                          name="edad"
                          type="text"
                          placeholder="Edad"
                          value={values.edad}
                          onChange={handleChange}
                        />
                        {touched.edad && errors.edad ? (
                          <div className="py-1 px-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.edad}
                          </div>
                        ) : null}
                      </div>
                      <div className="border-t col-span-12"></div>
                    </>
                  )}

                  <div className="col-span-12 md:col-span-6">
                    <Select
                      label="Estado civil"
                      name="edocivil"
                      value={values.edocivil}
                      onChange={handleChange}
                      array={estadoCivil}
                    />
                    {touched.edocivil && errors.edocivil ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.edocivil}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <Select
                      label="Último grado de estudios"
                      name="ultimoescolar"
                      value={values.ultimoescolar}
                      onChange={(e) => {
                        handleChange(e);
                        if (e.target.value == "Ninguno") {
                          setFieldValue("leer", "");
                          setFieldValue("escribir", "");
                        }
                      }}
                      array={ultimoGrado}
                    />
                    {touched.ultimoescolar && errors.ultimoescolar ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.ultimoescolar}
                      </div>
                    ) : null}
                  </div>
                  <div className="border-t col-span-12"></div>
                  {values.ultimoescolar == "Ninguno" && values.ultimoescolar != "" && (
                    <>
                      <div className="col-span-12 md:col-span-6">
                        <Select
                          label="¿Sabe leer?"
                          name="leer"
                          value={values.leer}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.leer && errors.leer ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.leer}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 md:col-span-6">
                        <Select
                          label="¿Sabe escribir?"
                          name="escribir"
                          value={values.escribir}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.escribir && errors.escribir ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.escribir}
                          </div>
                        ) : null}
                      </div>
                      <div className="border-t col-span-12"></div>
                    </>
                  )}
                  <div className="col-span-12 md:col-span-6">
                    <Select
                      label="¿La PAM pertenece a algún pueblo originario?"
                      name="indigena"
                      value={values.indigena}
                      onChange={handleChange}
                      array={SINO}
                    />
                    {touched.indigena && errors.indigena ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.indigena}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <Select
                      label="Habitualmente, la PAM vive en una casa/departamento"
                      name="viveen"
                      value={values.viveen}
                      onChange={(e) => {
                        handleChange(e);
                        if (e.target.value == "Propio") {
                          setFieldValue("herenciacasa", "");
                        }
                      }}
                      array={vivienda}
                    />
                    {touched.viveen && errors.viveen ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.viveen}
                      </div>
                    ) : null}
                  </div>
                  <div className="border-t col-span-12"></div>
                  {values.viveen == "Propio" && values.viveen != "" && (
                    <>
                      <div className="col-span-12">
                        <Select
                          label="¿Ha dado en herencia la casa/departamento en la cual usted actualmente vive?"
                          name="herenciacasa"
                          value={values.herenciacasa}
                          onChange={handleChange}
                          array={herencia}
                        />
                        {touched.herenciacasa && errors.herenciacasa ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.herenciacasa}
                          </div>
                        ) : null}
                      </div>
                      <div className="border-t col-span-12"></div>
                    </>
                  )}
                  <div className="col-span-12 md:col-span-6">
                    <Select
                      label="¿La PAM ya heredó (otros bienes) a sus hijos/hijas/pareja/cónyuge/otras personas?"
                      name="heredo"
                      value={values.heredo}
                      onChange={handleChange}
                      array={heredo}
                    />
                    {touched.heredo && errors.heredo ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.heredo}
                      </div>
                    ) : null}
                  </div>

                  <div className="col-span-12 md:col-span-6">
                    <Select
                      label="Habitualmente, la PAM vive con…"
                      name={"vivecon"}
                      value={values.vivecon}
                      onChange={(e) => {
                        handleChange(e);
                        if (e.target.value == "Cónyuge") {
                          setFieldValue("personas", "");
                          setFieldValue("menores", "");
                          setFieldValue("aliadulto1", "");
                          setFieldValue("aliadulto2", "");
                          setFieldValue("aliadulto3", "");
                          setFieldValue("aliadulto4", "");
                          setFieldValue("aliadulto5", "");
                          setFieldValue("aliadulto6", "");
                        }
                      }}
                      array={viveCon}
                    />
                    {touched.vivecon && errors.vivecon ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.vivecon}
                      </div>
                    ) : null}
                  </div>

                  {values.viveen != "Comunidad/asilo" && (
                    <>
                      <div className="col-span-12 md:col-span-6">
                        <Input
                          label="Incluyendo a la PAM, en el lugar en el cual habitualmente vive. ¿Cuántas personas residen?"
                          name="personas"
                          placeholder="Solo números"
                          type="text"
                          value={values.personas}
                          onChange={handleChange}
                        />
                        {touched.personas && errors.personas ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.personas}
                          </div>
                        ) : null}
                      </div>
                      {values.personas >= 2 && (
                        <div className="col-span-12 md:col-span-6">
                          <Input
                            label="De éstas, ¿cuántas son menores de edad?"
                            name="menores"
                            type="text"
                            placeholder="Solo números"
                            value={values.menores}
                            onChange={handleChange}
                          />
                          {touched.menores && errors.menores ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" /> {errors.menores}
                            </div>
                          ) : null}
                        </div>
                      )}
                      <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                        <h2 className="text-base font-bold leading-7 text-gray-900">
                          En los últimos tres meses, la PAM o algún adulto en la
                          vivienda en la cual la PAM habitualmente vive.
                        </h2>
                      </div>

                      <div className="col-span-12 md:col-span-6">
                        <Select
                          label="Dejó de desayunar, comer o cenar por falta de dinero?"
                          name="aliadulto1"
                          value={values.aliadulto1}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.aliadulto1 && errors.aliadulto1 ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.aliadulto1}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 md:col-span-6">
                        <Select
                          label="¿Comió menos de lo que debería comer por falta de dinero?"
                          name="aliadulto2"
                          value={values.aliadulto2}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.aliadulto2 && errors.aliadulto2 ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.aliadulto2}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 md:col-span-6">
                        <Select
                          label="¿Tuvo poca variedad en sus alimentos por falta de dinero?"
                          name="aliadulto3"
                          value={values.aliadulto3}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.aliadulto3 && errors.aliadulto3 ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.aliadulto3}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 md:col-span-6">
                        <Select
                          label="Comió sólo una vez al día o dejó de comer todo un día por falta de dinero?"
                          name="aliadulto4"
                          value={values.aliadulto4}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.aliadulto4 && errors.aliadulto4 ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.aliadulto4}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 md:col-span-6">
                        <Select
                          label="¿Sintió hambre, pero por falta de dinero no comió?"
                          name="aliadulto5"
                          value={values.aliadulto5}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.aliadulto5 && errors.aliadulto5 ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.aliadulto5}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 md:col-span-6">
                        <Select
                          label="¿Se quedó sin comida por falta de dinero?"
                          name="aliadulto6"
                          value={values.aliadulto6}
                          onChange={(e) => {
                            handleChange(e);
                            if (e.target.value == "No") {
                              setFieldValue("alimenor1", "");
                              setFieldValue("alimenor2", "");
                              setFieldValue("alimenor3", "");
                              setFieldValue("alimenor4", "");
                              setFieldValue("alimenor5", "");
                              setFieldValue("alimenor6", "");
                            }
                          }}
                          array={SINO}
                        />
                        {touched.aliadulto6 && errors.aliadulto6 ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.aliadulto6}
                          </div>
                        ) : null}
                      </div>

                      {values.menores > 0 && (
                        <>
                          <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                            <h2 className="text-base font-bold leading-7 text-gray-900">
                              En los últimos tres meses, algún menor … TODAS LAS
                              QUE APLIQUEN
                            </h2>
                          </div>
                          <div className="col-span-12 md:col-span-6">
                            <Select
                              label="Dejó de desayunar, comer o cenar por falta de dinero?"
                              name="alimenor1"
                              value={values.alimenor1}
                              onChange={handleChange}
                              array={SINO}
                            />
                            {touched.alimenor1 && errors.alimenor1 ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" />{" "}
                                {errors.alimenor1}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 md:col-span-6">
                            <Select
                              label="¿Comió menos de lo que debería comer por falta de dinero?"
                              name="alimenor2"
                              value={values.alimenor2}
                              onChange={handleChange}
                              array={SINO}
                            />
                            {touched.alimenor2 && errors.alimenor2 ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" />{" "}
                                {errors.alimenor2}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 md:col-span-6">
                            <Select
                              label="¿Tuvo poca variedad en sus alimentos por falta de dinero?"
                              name="alimenor3"
                              value={values.alimenor3}
                              onChange={handleChange}
                              array={SINO}
                            />
                            {touched.alimenor3 && errors.alimenor3 ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" />{" "}
                                {errors.alimenor3}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 md:col-span-6">
                            <Select
                              label="Comió sólo una vez al día o dejó de comer todo un día por falta de dinero?"
                              name="alimenor4"
                              value={values.alimenor4}
                              onChange={handleChange}
                              array={SINO}
                            />
                            {touched.alimenor4 && errors.alimenor4 ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" />{" "}
                                {errors.alimenor4}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 md:col-span-6">
                            <Select
                              label="¿Sintió hambre, pero por falta de dinero no comió?"
                              name="alimenor5"
                              value={values.alimenor5}
                              onChange={handleChange}
                              array={SINO}
                            />
                            {touched.alimenor5 && errors.alimenor5 ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" />{" "}
                                {errors.alimenor5}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 md:col-span-6">
                            <Select
                              label="¿Se quedó sin comida por falta de dinero?"
                              name="alimenor6"
                              value={values.alimenor6}
                              onChange={handleChange}
                              array={SINO}
                            />
                            {touched.alimenor6 && errors.alimenor6 ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" />{" "}
                                {errors.alimenor6}
                              </div>
                            ) : null}
                          </div>
                        </>
                      )}
                    </>
                  )}
                  <div className="border-t col-span-12"></div>
                  <div className="col-span-12 md:col-span-6">
                    <Select
                      label="La PAM tiene seguridad social por parte de:"
                      name="seguridadsocial"
                      value={values.seguridadsocial}
                      onChange={handleChange}
                      array={seguridadSocial}
                    />
                    {touched.seguridadsocial && errors.seguridadsocial ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.seguridadsocial}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <Select
                      label="Si se enferma, ¿a quién acude?"
                      name="atnmedica"
                      value={values.atnmedica}
                      onChange={handleChange}
                      array={atencionMedica}
                    />
                    {touched.atnmedica && errors.atnmedica ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.atnmedica}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <Select
                      label="¿La PAM trabaja?"
                      name="trabaja"
                      value={values.trabaja}
                      onChange={(e) => {
                        handleChange(e);
                        if (e.target.value == "No") {
                          setFieldValue("horastrabajo", "");
                          setFieldValue("remuneracion", "");
                          setFieldValue("soloespecie", "");
                        }
                      }}
                      array={SINO}
                    />
                    {touched.trabaja && errors.trabaja ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.trabaja}
                      </div>
                    ) : null}
                  </div>
                  {values.trabaja == "Si" && (
                    <>
                      <div className="border-t col-span-12"></div>
                      <div className="col-span-12 md:col-span-6">
                        <Select
                          label="Por semana, apróximadamente, ¿cuántas horas dedica al trabajo?"
                          name="horastrabajo"
                          value={values.horastrabajo}
                          onChange={handleChange}
                          array={horasTrabajo}
                        />
                        {touched.horastrabajo && errors.horastrabajo ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.horastrabajo}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 md:col-span-6">
                        <Select
                          label="¿Qué remuneración/ingresos recibe cada quincena por su trabajo?"
                          name="remuneracion"
                          value={values.remuneracion}
                          onChange={handleChange}
                          array={remuneracion}
                        />
                        {touched.remuneracion && errors.remuneracion ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.remuneracion}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 md:col-span-6">
                        <Select
                          label="Me pagan en especie (ej. comida, hospedaje)"
                          name="soloespecie"
                          value={values.soloespecie}
                          onChange={handleChange}
                          array={SINONOSABE}
                        />
                        {touched.soloespecie && errors.soloespecie ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.soloespecie}
                          </div>
                        ) : null}
                      </div>
                      <div className="border-t col-span-12"></div>
                    </>
                  )}
                  <div className="col-span-12 md:col-span-6">
                    <div className="py-0">
                      <label htmlFor="otrosingresos" className="block text-sm font-medium leading-6 text-gray-900">Si tiene otros ingresos monetarios, ¿Apróximadamente qué cantidad recibe cada mes? (en pesos)</label>
                      <Input
                        name="otrosingresos"
                        type="text"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#621132] sm:text-sm sm:leading-6"
                        placeholder="Otros ingresos"
                        value={values.otrosingresos}
                        onChange={
                          (e) => { setFieldValue('otrosingresos', e.target.value) }
                        }
                      />
                      {touched.otrosingresos && errors.otrosingresos ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.otrosingresos}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <div className="py-0">
                      <label htmlFor="otrosingresos" className="block text-sm font-medium leading-6 text-gray-900">¿Qué cantidad tiene ahorrada? (en pesos)</label>
                      <Input
                        name="otrosingresos"
                        type="text"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#621132] sm:text-sm sm:leading-6"
                        placeholder="Ahorro"
                        value={values.ahorro}
                        onChange={
                          (e) => { setFieldValue('ahorro', e.target.value) }
                        }
                      />
                      {touched.ahorro && errors.ahorro ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.ahorro}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <div className="py-0">
                      <label htmlFor="deuda" className="block text-sm font-medium leading-6 text-gray-900">¿Qué cantidad debe en este momento (deuda)? (en pesos)</label>
                      <Input
                        name="deuda"
                        type="text"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#621132] sm:text-sm sm:leading-6"
                        placeholder="Deuda"
                        value={values.deuda}
                        onChange={
                          (e) => { setFieldValue('deuda', e.target.value) }
                        }
                      />
                      {touched.deuda && errors.deuda ? (
                        <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                          <ErrorIcon fontSize="small" /> {errors.deuda}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <Select
                      label="¿La PAM necesita apoyo económico?"
                      name="necapoyoeconomico"
                      value={values.necapoyoeconomico}
                      onChange={handleChange}
                      array={SINO}
                    />
                    {touched.necapoyoeconomico && errors.necapoyoeconomico ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" />{" "}
                        {errors.necapoyoeconomico}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <Select
                      label="¿Algún familiar apoya la PAM económicamente?"
                      name="recibeapoyoeconomico"
                      value={values.recibeapoyoeconomico}
                      onChange={(e) => {
                        handleChange(e);
                        if (e.target.value == 0) {
                          setFieldValue("cantidadapoyoeconomico", "");
                        }
                      }}
                      array={SINO}
                    />
                    {touched.recibeapoyoeconomico &&
                      errors.recibeapoyoeconomico ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" />{" "}
                        {errors.recibeapoyoeconomico}
                      </div>
                    ) : null}
                  </div>
                  {values.recibeapoyoeconomico == "Si" && (
                    <>
                      <div className="col-span-12 md:col-span-6">
                        <div className="py-0">
                          <label htmlFor="cantidadapoyoeconomico" className="block text-sm font-medium leading-6 text-gray-900">Apróximadamente, ¿Con qué cantidad lo apoyan mensualmente? (en pesos)</label>
                          <Input
                            name="cantidadapoyoeconomico"
                            type="text"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#621132] sm:text-sm sm:leading-6"
                            placeholder="Cantidad"
                            value={values.cantidadapoyoeconomico}
                            onChange={
                              (e) => { setFieldValue('cantidadapoyoeconomico', e.target.value) }
                            }
                          />
                          {touched.cantidadapoyoeconomico && errors.cantidadapoyoeconomico ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" /> {errors.cantidadapoyoeconomico}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </>
                  )}
                  <div className="col-span-12 md:col-span-6">
                    <Select
                      label="¿La PAM considera necesitar apoyo en especie?"
                      name="necapoyoespecie"
                      value={values.necapoyoespecie}
                      onChange={handleChange}
                      array={SINO}
                    />
                    {touched.necapoyoespecie && errors.necapoyoespecie ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.necapoyoespecie}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <Select
                      label="¿Algún familiar apoya la PAM en especie?"
                      name="recibeapoyoespecie"
                      value={values.recibeapoyoespecie}
                      onChange={(e) => {
                        handleChange(e);
                        if (e.target.value == "No") {
                          setFieldValue("apoyoespeciealimentos", "");
                          setFieldValue("apoyoespeciearticulos", "");
                          setFieldValue("apoyoespecietraslados", "");
                          setFieldValue("apoyoespecietiempo", "");
                          setFieldValue("apoyoespecieotro", "");
                        }
                      }}
                      array={SINO}
                    />
                    {touched.recibeapoyoespecie && errors.recibeapoyoespecie ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" />{" "}
                        {errors.recibeapoyoespecie}
                      </div>
                    ) : null}
                  </div>
                  {values.recibeapoyoespecie == "Si" && (
                    <>
                      <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                        <h2 className="text-base font-bold leading-7 text-gray-900">
                          ¿Con qué lo apoyan mensualmente?
                        </h2>
                      </div>
                      <div className="col-span-12 md:col-span-6">
                        <Select
                          label="Alimentos/ropa"
                          name="apoyoespeciealimentos"
                          value={values.apoyoespeciealimentos}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.apoyoespeciealimentos &&
                          errors.apoyoespeciealimentos ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" />{" "}
                            {errors.apoyoespeciealimentos}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 md:col-span-6">
                        <Select
                          label="Medicinas/pañales/artículos higiene personal"
                          name="apoyoespeciemedicinas"
                          value={values.apoyoespeciemedicinas}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.apoyoespeciemedicinas &&
                          errors.apoyoespeciemedicinas ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" />{" "}
                            {errors.apoyoespeciemedicinas}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 md:col-span-6">
                        <Select
                          label="Traslados/transporte"
                          name="apoyoespecietraslados"
                          value={values.apoyoespecietraslados}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.apoyoespecietraslados &&
                          errors.apoyoespecietraslados ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" />{" "}
                            {errors.apoyoespecietraslados}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 md:col-span-6">
                        <Select
                          label="Me dedican tiempo/Están conmigo"
                          name="apoyoespecietiempo"
                          value={values.apoyoespecietiempo}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.apoyoespecietiempo &&
                          errors.apoyoespecietiempo ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" />{" "}
                            {errors.apoyoespecietiempo}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12">
                        <Select
                          label="Otro (cualquiera distinto de los anteriores)"
                          name="apoyoespecieotro"
                          value={values.apoyoespecieotro}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.apoyoespecieotro && errors.apoyoespecieotro ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" />{" "}
                            {errors.apoyoespecieotro}
                          </div>
                        ) : null}
                      </div>
                      <div className="border-t col-span-12"></div>
                    </>
                  )}
                  <div className="col-span-12">
                    <Select
                      label="¿La PAM proporciona algún apoyo a sus hijos?"
                      name="apoyasushijos"
                      value={values.apoyasushijos}
                      onChange={(e) => {
                        handleChange(e);
                        if (e.target.value == "No") {
                          setFieldValue("cuidonietos", "");
                          setFieldValue("llevoalaescuela", "");
                          setFieldValue("doydinero", "");
                          setFieldValue("pagoservicios", "");
                          setFieldValue("ayudoenlacasa", "");
                        }
                      }}
                      array={SINO}
                    />
                    {touched.apoyasushijos && errors.apoyasushijos ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.apoyasushijos}
                      </div>
                    ) : null}
                  </div>
                  <div className="border-t col-span-12"></div>
                  {values.apoyasushijos == "Si" && (
                    <>
                      <div className="col-span-12 md:col-span-6">
                        <Select
                          label="Cuido los nietos"
                          name="cuidonietos"
                          value={values.cuidonietos}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.cuidonietos && errors.cuidonietos ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.cuidonietos}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 md:col-span-6">
                        <Select
                          label="Llevo los nietos a la escuela"
                          name="llevoalaescuela"
                          value={values.llevoalaescuela}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.llevoalaescuela && errors.llevoalaescuela ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" />{" "}
                            {errors.llevoalaescuela}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 md:col-span-6">
                        <Select
                          label="Les doy dinero (ej. para la comida)"
                          name="doydinero"
                          value={values.doydinero}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.doydinero && errors.doydinero ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.doydinero}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 md:col-span-6">
                        <Select
                          label="Pago los servicios (ej. luz, agua, gas)"
                          name="pagoservicios"
                          value={values.pagoservicios}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.pagoservicios && errors.pagoservicios ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" />{" "}
                            {errors.pagoservicios}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12">
                        <Select
                          label="Ayudo en la casa (ej. lavo trastes)"
                          name="ayudoenlacasa"
                          value={values.ayudoenlacasa}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.ayudoenlacasa && errors.ayudoenlacasa ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" />{" "}
                            {errors.ayudoenlacasa}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12">
                        <Select
                          label="Viven en mi casa"
                          name="vivenenmicasa"
                          value={values.vivenenmicasa}
                          onChange={(e) => {
                            handleChange(e)
                          }}
                          array={SINO}
                        />
                        {touched.vivenenmicasa && errors.vivenenmicasa ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" />{" "}
                            {errors.vivenenmicasa}
                          </div>
                        ) : null}
                      </div>
                      <div className="border-t col-span-12"></div>
                    </>
                  )}

                  <div className="col-span-12">
                    <Select
                      label="Si no recibiera ayuda (en especie o monetaria) por parte de familiares, ¿los ingresos de la PAM alcanzan para cubrir sus necesidades?"
                      name="alcanza"
                      value={values.alcanza}
                      onChange={handleChange}
                      array={alzanza}
                    />
                    {touched.alcanza && errors.alcanza ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.alcanza}
                      </div>
                    ) : null}
                  </div>
                  <div className="border-t col-span-12"></div>
                  <div className="col-span-12">
                    <Select
                      label="¿La PAM está inscrito en el Programa Pensión para el Bienestar y más y tiene tarjeta del mismo?"
                      name="inscritoprograma65ymas"
                      value={values.inscritoprograma65ymas}
                      onChange={(e) => {
                        handleChange(e);
                        if (e.target.value == "No estoy inscrito") {
                          setFieldValue("usatarjeta65ymas", "");
                          setFieldValue("disponerecursos65ymas", "");
                        }
                      }}
                      array={inscritoPrograma}
                    />
                    {touched.inscritoprograma65ymas &&
                      errors.inscritoprograma65ymas ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" />{" "}
                        {errors.inscritoprograma65ymas}
                      </div>
                    ) : null}
                  </div>
                  {(values.inscritoprograma65ymas == "Si, sin tarjeta" ||
                    values.inscritoprograma65ymas == "Si, con tarjeta") && (
                      <>
                        <div className="border-t col-span-12"></div>
                        <div className="col-span-12 md:col-span-6">
                          <Select
                            label="¿La PAM usa la tarjeta del Programa Pensión para el Bienestar ?"
                            name="usatarjeta65ymas"
                            value={values.usatarjeta65ymas}
                            onChange={handleChange}
                            array={usaTarjeta}
                          />
                          {touched.usatarjeta65ymas && errors.usatarjeta65ymas ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.usatarjeta65ymas}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-span-12 md:col-span-6">
                          <Select
                            label="¿La PAM decide cómo se utilizan los recursos del Programa Pensión para el Bienestar?"
                            name="disponerecursos65ymas"
                            value={values.disponerecursos65ymas}
                            onChange={handleChange}
                            array={dispone65}
                          />
                          {touched.disponerecursos65ymas &&
                            errors.disponerecursos65ymas ? (
                            <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                              <ErrorIcon fontSize="small" />{" "}
                              {errors.disponerecursos65ymas}
                            </div>
                          ) : null}
                        </div>
                        <div className="border-t col-span-12"></div>
                      </>
                    )}
                  <div className="col-span-12">
                    <Select
                      label="Además de la tarjeta del Programa Pensión para el Bienestar, ¿La PAM tiene alguna otra tarjeta/cuenta bancaria?"
                      name="cuentabancaria"
                      value={values.cuentabancaria}
                      onChange={(e) => {
                        handleChange(e);
                        if (e.target.value == "No") {
                          setFieldValue("manejotrascuentas", "");
                          setFieldValue("atmyayuda", "");
                          setFieldValue("acudebancoayuda", "");
                        }
                      }}
                      array={SINONOSABE}
                    />
                    {touched.cuentabancaria && errors.cuentabancaria ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.cuentabancaria}
                      </div>
                    ) : null}
                  </div>
                  {values.cuentabancaria == "Si" && (
                    <>
                      <div className="border-t col-span-12"></div>
                      <div className="col-span-12 md:col-span-6">
                        <Select
                          label="Si tiene otras tarjetas o cuentas bancarias, además del Programa Pensión para el Bienestar, generalmente ¿las maneja la PAM?"
                          name="manejotrascuentas"
                          value={values.manejotrascuentas}
                          onChange={handleChange}
                          array={manejoCuentas}
                        />
                        {touched.manejotrascuentas &&
                          errors.manejotrascuentas ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" />{" "}
                            {errors.manejotrascuentas}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 md:col-span-6">
                        <Select
                          label="Si tiene tarjeta/cuenta bancaria (programa pensión para el bienestar u otra), cuando va al cajero automático…"
                          name="atmyayuda"
                          value={values.atmyayuda}
                          onChange={handleChange}
                          array={ayuda}
                        />
                        {touched.atmyayuda && errors.atmyayuda ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.atmyayuda}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12">
                        <Select
                          label="Si tiene tarjeta/cuenta bancaria (programa pensión para el bienestar u otra), ¿cuándo acude al banco, ¿necesita ayuda?"
                          name="acudebancoayuda"
                          value={values.acudebancoayuda}
                          onChange={handleChange}
                          array={acudeBanco}
                        />
                        {touched.acudebancoayuda && errors.acudebancoayuda ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" />{" "}
                            {errors.acudebancoayuda}
                          </div>
                        ) : null}
                      </div>
                    </>
                  )}

                  <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                    <h2 className="text-base font-bold leading-7 text-gray-900">
                      ¿La PAM padece alguna enfermedad de la lista a continuación? Indique todas las que padece:
                    </h2>
                  </div>

                  <div className="col-span-12 md:col-span-6">
                    <Select
                      label="Asma bronquial"
                      name="asma"
                      value={values.asma}
                      onChange={handleChange}
                      array={SINONOSABE}
                    />
                    {touched.asma && errors.asma ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.asma}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <Select
                      label="Hipercolesterolemia (colesterol alto)"
                      name="colesterol"
                      value={values.colesterol}
                      onChange={handleChange}
                      array={SINONOSABE}
                    />
                    {touched.colesterol && errors.colesterol ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.colesterol}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <Select
                      label="Enfermedades cerebrovasculares"
                      name="ecv"
                      value={values.ecv}
                      onChange={handleChange}
                      array={SINONOSABE}
                    />
                    {touched.ecv && errors.ecv ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.ecv}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <Select
                      label="Cardiopatía isquémica (problemas cardíacos)"
                      name="cardio"
                      value={values.cardio}
                      onChange={handleChange}
                      array={SINONOSABE}
                    />
                    {touched.cardio && errors.cardio ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.cardio}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <Select
                      label="Hipertensión arterial (presión alta)"
                      name="hipertension"
                      value={values.hipertension}
                      onChange={handleChange}
                      array={SINONOSABE}
                    />
                    {touched.hipertension && errors.hipertension ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.hipertension}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <Select
                      label="Cáncer"
                      name="cancer"
                      value={values.cancer}
                      onChange={handleChange}
                      array={SINONOSABE}
                    />
                    {touched.cancer && errors.cancer ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.cancer}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <Select
                      label="EPOC-Enfermedad pulmonar obstructiva crónica"
                      name="epoc"
                      value={values.epoc}
                      onChange={handleChange}
                      array={SINONOSABE}
                    />
                    {touched.epoc && errors.epoc ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.epoc}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <Select
                      label="Diabetes "
                      name="diabetes"
                      value={values.diabetes}
                      onChange={handleChange}
                      array={SINONOSABE}
                    />
                    {touched.diabetes && errors.diabetes ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.diabetes}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <Select
                      label="Osteoporosis"
                      name="osteoporosis"
                      value={values.osteoporosis}
                      onChange={handleChange}
                      array={SINONOSABE}
                    />
                    {touched.osteoporosis && errors.osteoporosis ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.osteoporosis}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <Select
                      label="Artrosis"
                      name="artrosis"
                      value={values.artrosis}
                      onChange={handleChange}
                      array={SINONOSABE}
                    />
                    {touched.artrosis && errors.artrosis ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.artrosis}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 md:col-span-6">
                    <Select
                      label="Insuficiencia renal"
                      name="renal"
                      value={values.renal}
                      onChange={handleChange}
                      array={SINONOSABE}
                    />
                    {touched.renal && errors.renal ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.renal}
                      </div>
                    ) : null}
                  </div>
                </>
              )}

              <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                <h2 className="text-base font-bold leading-7 text-gray-900">
                  ¿La PAM tiene alguna discapacidad?
                </h2>
              </div>

              <div className="col-span-12 md:col-span-6">
                <Select
                  label="Motriz"
                  name="dismotriz"
                  value={values.dismotriz}
                  onChange={handleChange}
                  array={SINO}
                />
                {touched.dismotriz && errors.dismotriz ? (
                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                    <ErrorIcon fontSize="small" /> {errors.dismotriz}
                  </div>
                ) : null}
              </div>
              <div className="col-span-12 md:col-span-6">
                <Select
                  label="Auditiva"
                  name="disauditiva"
                  value={values.disauditiva}
                  onChange={handleChange}
                  array={SINO}
                />
                {touched.disauditiva && errors.disauditiva ? (
                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                    <ErrorIcon fontSize="small" /> {errors.disauditiva}
                  </div>
                ) : null}
              </div>
              <div className="col-span-12 md:col-span-6">
                <Select
                  label="Visual"
                  name="disvisual"
                  value={values.disvisual}
                  onChange={handleChange}
                  array={SINO}
                />
                {touched.disvisual && errors.disvisual ? (
                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                    <ErrorIcon fontSize="small" /> {errors.disvisual}
                  </div>
                ) : null}
              </div>
              <div className="col-span-12 md:col-span-6">
                <Select
                  label="Del habla"
                  name="dishabla"
                  value={values.dishabla}
                  onChange={handleChange}
                  array={SINO}
                />
                {touched.dishabla && errors.dishabla ? (
                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                    <ErrorIcon fontSize="small" /> {errors.dishabla}
                  </div>
                ) : null}
              </div>
              <div className="col-span-12 md:col-span-6">
                <Select
                  label="Otra"
                  name="disotra"
                  value={values.disotra}
                  onChange={handleChange}
                  array={SINO}
                />
                {touched.disotra && errors.disotra ? (
                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                    <ErrorIcon fontSize="small" /> {errors.disotra}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="flex justify-end mt-6 gap-5">
              <button
                type="button"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-claret-800 hover:bg-claret-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => {
                  navigation("/");
                }}
              >
                Regresar
              </button>
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-claret-900 hover:bg-claret-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Siguiente
              </button>

            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
