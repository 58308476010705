/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Input from "../../components/Input";
import Textareas from "../../components/Textareas";
import Select from "../../components/Select";
import { SINO } from "../../utils/selectors";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ErrorIcon from "@mui/icons-material/Error";

export default function Dinero() {
  const navigation = useNavigate();
  const [familia, setFamilia] = React.useState();
  const [loading, setloading] = useState(false)

  React.useEffect(() => {
    const esFamilia = localStorage.getItem("familiar");
    setFamilia(esFamilia);
  }, []);

  const validateSchema = Yup.object({
    movilidad: Yup.string().required("Campo requerido"),
    movilidadmeses: Yup.string().when("movilidad", {
      is: "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    movilidadhijos: Yup.string().when("movilidad", {
      is: "Si",
      then: () => Yup.number().typeError('Sólo números').required("Campo requerido"),
    }),
    movilidadnietos: Yup.string().when("movilidad", {
      is: "Si",
      then: () => Yup.number().typeError('Sólo números').required("Campo requerido"),
    }),
    movilidadpareja: Yup.string().when("movilidad", {
      is: "Si",
      then: () => Yup.number().typeError('Sólo números').required("Campo requerido"),
    }),
    movilidadyernonuera: Yup.string().when("movilidad", {
      is: "Si",
      then: () => Yup.number().typeError('Sólo números').required("Campo requerido"),
    }),
    movilidadotros: Yup.string().when("movilidad", {
      is: "Si",
      then: () => Yup.number().typeError('Sólo números').required("Campo requerido"),
    }),
    movilidadtestigo: Yup.string().when("movilidad", {
      is: "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    movilidadintoxicado:
      familia === "Si" &&
      Yup.string().when("movilidad", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    movilidaddomicilio:
      familia === "Si" &&
      Yup.string().when("movilidad", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    movilidadeconomico:
      familia === "Si" &&
      Yup.string().when("movilidad", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    movilidaddefensa:
      familia == "Si" &&
      Yup.string().when("movilidad", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    movilidaddefensahijos:
      familia == "Si" &&
      Yup.string().when("movilidad", {
        is: "Si",
        then: () => Yup.string().when("movilidaddefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    movilidaddefensanietos:
      familia == "Si" &&
      Yup.string().when("movilidad", {
        is: "Si",
        then: () => Yup.string().when("movilidaddefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    movilidaddefensapareja:
      familia == "Si" &&
      Yup.string().when("movilidad", {
        is: "Si",
        then: () => Yup.string().when("movilidaddefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    movilidaddefensayerno:
      familia == "Si" &&
      Yup.string().when("movilidad", {
        is: "Si",
        then: () => Yup.string().when("movilidaddefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    movilidaddefensaotros:
      familia == "Si" &&
      Yup.string().when("movilidad", {
        is: "Si",
        then: () => Yup.string().when("movilidaddefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    movilidadinforma:
      familia === "Si" &&
      Yup.string().when("movilidad", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    movilidadinformafamiliares:
      familia === "Si" &&
      Yup.string().when("movilidad", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    movilidadinformaamigos:
      familia === "Si" &&
      Yup.string().when("movilidad", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    movilidadinformasacerdote:
      familia === "Si" &&
      Yup.string().when("movilidad", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    movilidadinformahospital:
      familia === "Si" &&
      Yup.string().when("movilidad", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    movilidadinformabienestar:
      familia === "Si" &&
      Yup.string().when("movilidad", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    movilidadinformapolicia:
      familia === "Si" &&
      Yup.string().when("movilidad", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    movilidadnoinformanosabe:
      familia == "Si" && Yup.string()
        .when(["movilidad", "movilidadinforma"], {
          is: (movilidad, movilidadinforma) => movilidad == "Si" && movilidadinforma == "No",
          then: () => Yup.string().required("Campo requerido"),
        }),
    movilidadnoinformanoapoyo:
      familia == "Si" && Yup.string()
        .when(["movilidad", "movilidadinforma"], {
          is: (movilidad, movilidadinforma) => movilidad == "Si" && movilidadinforma == "No",
          then: () => Yup.string().required("Campo requerido"),
        }),
    movilidadnoinformanoperjudicar:
      familia == "Si" && Yup.string()
        .when(["movilidad", "movilidadinforma"], {
          is: (movilidad, movilidadinforma) => movilidad == "Si" && movilidadinforma == "No",
          then: () => Yup.string().required("Campo requerido"),
        }),
    movilidadnoinformaempeoraria:
      familia == "Si" && Yup.string()
        .when(["movilidad", "movilidadinforma"], {
          is: (movilidad, movilidadinforma) => movilidad == "Si" && movilidadinforma == "No",
          then: () => Yup.string().required("Campo requerido"),
        })


  })

  return (
    <div>
      <Formik
        initialValues={{
          movilidad: "",
          movilidadmeses: "",
          movilidadhijos: "",
          movilidadnietos: "",
          movilidadpareja: "",
          movilidadyernonuera: "",
          movilidadotros: "",
          movilidadtestigo: "",
          movilidadintoxicado: "",
          movilidaddomicilio: "",
          movilidaddefensa: "",
          movilidaddefensahijos: "",
          movilidaddefensanietos: "",
          movilidaddefensapareja: "",
          movilidaddefensayerno: "",
          movilidaddefensaotros: "",
          movilidadeconomico: "",
          movilidadinforma: "",
          movilidadinformafamiliares: "",
          movilidadinformaamigos: "",
          movilidadinformasacerdote: "",
          movilidadinformahospital: "",
          movilidadinformabienestar: "",
          movilidadinformapolicia: "",
          movilidadnoinformanosabe: "",
          movilidadnoinformanoapoyo: "",
          movilidadnoinformanoperjudicar: "",
          movilidadnoinformaempeoraria: "",

          activarcomentario: false,
          comentariodenuncia: ""
        }}
        enableReinitialize={true}
        onSubmit={(values) => {
          setloading(true)
          const denunciante = JSON.parse(
            localStorage.getItem("InformacionDenuncianteParte1")
          );
          const pam = JSON.parse(localStorage.getItem("pam"));
          const abandona = JSON.parse(localStorage.getItem("abandona"));
          const malno = JSON.parse(localStorage.getItem("malno"));
          const vf = JSON.parse(localStorage.getItem("vf"));
          const vp = JSON.parse(localStorage.getItem("vp"));
          const sexual = JSON.parse(localStorage.getItem("sexual"));
          const dinero = JSON.parse(localStorage.getItem("dinero"));
          const movilidad = values;

          // unir todos los objetos en uno solo
          const newAll = {
            ...denunciante,
            ...pam,
            ...abandona,
            ...malno,
            ...vf,
            ...vp,
            ...sexual,
            ...dinero,
            ...movilidad
          };

          fetch("https://tlaxcala-back.vercel.app/api/save", {
            //fetch("http://localhost:8000/api/save", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newAll),
          })
            .then((response) => response.json())
            .then((data) => {
              setloading(false)
              console.log(data)
              Swal.fire({
                title: "¡Gracias por tu denuncia!",
                text: "Tu denuncia ha sido enviada exitosamente",
                icon: "success",
                confirmButtonText: "Aceptar",
              }).then((result) => {
                if (result.isConfirmed) {
                  // localStorage.clear();
                  // navigation("/");
                }
              });
            })
            .catch((error) => {
              setloading(false)
              console.log(error)
              Swal.fire({
                title: "¡Algo salió mal!",
                text: error,
                icon: "error",
                confirmButtonText: "Aceptar",
              });
            });
        }}
        validationSchema={validateSchema}
      >
        {({ values, handleChange, resetForm, setFieldValue, touched, errors }) => (
          <Form>
            <div className="grid grid-cols-1 gap-y-6 md:grid-cols-12 gap-x-6 p-2">
              <div className="col-span-12">
                <Select
                  label="7.  ¿Alguien le impide salir de su casa sin motivo aparente, visitar y/o llamar por teléfono a otros familiares/amigos?"
                  name="movilidad"
                  value={values.movilidad}
                  onChange={(e) => {
                    handleChange(e)
                    if (e.target.value === "No") {
                      resetForm();
                      const movilidad = e.target.value;
                      setFieldValue("movilidad", movilidad);
                    }
                  }}
                  array={SINO}
                />
                {touched.movilidad && errors.movilidad ? (
                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                    <ErrorIcon fontSize="small" /> {errors.movilidad}
                  </div>
                ) : null}
              </div>
              {values.movilidad === "Si" && (
                <>
                  <div className="col-span-12">
                    <Input
                      label="7.2 ¿Desde hace cuánto tiempo ocurre esto? (indica con palabras, si años, meses o días)"
                      name="movilidadmeses"
                      placeholder="Inserta cuanto tiempo"
                      type="text"
                      value={values.movilidadmeses}
                      onChange={handleChange}
                    />
                    {touched.movilidadmeses && errors.movilidadmeses ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.movilidadmeses}
                      </div>
                    ) : null}
                  </div>
                  <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                    <h2 className="text-base font-bold leading-7 text-gray-900">
                      ¿Quién lo hizo y cuántas veces ocurrió en el último mes?
                    </h2>
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Hijos/Hijas"
                      name="movilidadhijos"
                      placeholder="Hijos"
                      value={values.movilidadhijos}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.movilidadhijos && errors.movilidadhijos ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.movilidadhijos}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Nietos/Nietas"
                      name="movilidadnietos"
                      placeholder="Nietos"
                      value={values.movilidadnietos}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.movilidadnietos && errors.movilidadnietos ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.movilidadnietos}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Pareja"
                      name="movilidadpareja"
                      placeholder="Pareja"
                      value={values.movilidadpareja}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.movilidadpareja && errors.movilidadpareja ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.movilidadpareja}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Yerno/Nuera"
                      placeholder="Yerno/Nuera"
                      name="movilidadyernonuera"
                      value={values.movilidadyernonuera}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.movilidadyernonuera && errors.movilidadyernonuera ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.movilidadyernonuera}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Otros"
                      placeholder="Otros"
                      name="movilidadotros"
                      value={values.movilidadotros}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.movilidadotros && errors.movilidadotros ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.movilidadotros}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Select
                      label="No sabe, pero lo atestiguó"
                      name="movilidadtestigo"
                      value={values.movilidadtestigo}
                      onChange={handleChange}
                      array={SINO}
                    />
                    {touched.movilidadtestigo && errors.movilidadtestigo ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.movilidadtestigo}
                      </div>
                    ) : null}
                  </div>
                  {familia === "Si" && (
                    <>
                      <div className="border-t col-span-12"></div>
                      <div className="col-span-12">
                        <Select
                          label="7.3 ¿Estos episodios ocurren cuando el agresor se encuentra intoxicado (tomó alcohol, alguna droga)?"
                          name="movilidadintoxicado"
                          value={values.movilidadintoxicado}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.movilidadintoxicado && errors.movilidadintoxicado ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.movilidadintoxicado}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="¿El/la/los agresores viven en el mismo domicilio con usted?"
                          name="movilidaddomicilio"
                          value={values.movilidaddomicilio}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.movilidaddomicilio && errors.movilidaddomicilio ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.movilidaddomicilio}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="¿Estos episodios ocurren cuando el agresor tiene problemas económicos?"
                          name="movilidadeconomico"
                          value={values.movilidadeconomico}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.movilidadeconomico && errors.movilidadeconomico ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.movilidadeconomico}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-12">
                        <Select
                          label="Cuando estos episodios ocurren, ¿alguien entra en su defensa?"
                          name="movilidaddefensa"
                          value={values.movilidaddefensa}
                          onChange={(e) => {
                            handleChange(e);
                            if (e.target.value === "No") {
                              setFieldValue('movilidaddefensahijos', "")
                              setFieldValue('movilidaddefensanietos', "")
                              setFieldValue('movilidaddefensapareja', "")
                              setFieldValue('movilidaddefensayerno', "")
                              setFieldValue('movilidaddefensaotros', "")
                            }
                          }}
                          array={SINO}
                        />
                        {touched.movilidaddefensa && errors.movilidaddefensa ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.movilidaddefensa}
                          </div>
                        ) : null}
                      </div>
                      {values.movilidaddefensa == "Si" && (
                        <>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Hijos/Hijas"
                              name="movilidaddefensahijos"
                              placeholder="Hijos"
                              value={values.movilidaddefensahijos}
                              onChange={handleChange}
                              type="text"
                            />
                            {touched.movilidaddefensahijos && errors.movilidaddefensahijos ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.movilidaddefensahijos}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Nietos/Nietas"
                              name="movilidaddefensanietos"
                              placeholder="Nietos"
                              value={values.movilidaddefensanietos}
                              onChange={handleChange}
                              type="text"
                            />
                            {touched.movilidaddefensanietos && errors.movilidaddefensanietos ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.movilidaddefensanietos}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Pareja/esposo/esposa"
                              name="movilidaddefensapareja"
                              placeholder="Pareja/esposo/esposa"
                              value={values.movilidaddefensapareja}
                              onChange={handleChange}
                              type="text"
                            />
                            {touched.movilidaddefensapareja && errors.movilidaddefensapareja ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.movilidaddefensapareja}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Yerno/Nuera"
                              name="movilidaddefensayerno"
                              placeholder="Yerno"
                              value={values.movilidaddefensayerno}
                              onChange={handleChange}
                              type="text"
                            />
                            {touched.movilidaddefensayerno && errors.movilidaddefensayerno ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.movilidaddefensayerno}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Otras personas que no son familiares directos"
                              name="movilidaddefensaotros"
                              placeholder="Otros"
                              value={values.movilidaddefensaotros}
                              onChange={handleChange}
                              type="text"
                            />
                            {touched.movilidaddefensaotros && errors.movilidaddefensaotros ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.movilidaddefensaotros}
                              </div>
                            ) : null}
                          </div>
                        </>
                      )}
                      <div className="border-t col-span-12"></div>
                      <div className="col-span-12">
                        <Select
                          label="7.4 En caso alguien le impida poder salir de su casa sin motivo aparente, visitar y/o llamar por teléfono a otros familiares/amigos, ¿La PAM ha informado estos hechos a alguna persona? (indique todos los casos que apliquen)"
                          name="movilidadinforma"
                          value={values.movilidadinforma}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.movilidadinforma && errors.movilidadinforma ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.movilidadinforma}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Familiares"
                          name="movilidadinformafamiliares"
                          value={values.movilidadinformafamiliares}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.movilidadinformafamiliares && errors.movilidadinformafamiliares ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.movilidadinformafamiliares}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Amigos"
                          name="movilidadinformaamigos"
                          value={values.movilidadinformaamigos}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.movilidadinformaamigos && errors.movilidadinformaamigos ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.movilidadinformaamigos}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Sacerdote"
                          name="movilidadinformasacerdote"
                          value={values.movilidadinformasacerdote}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.movilidadinformasacerdote && errors.movilidadinformasacerdote ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.movilidadinformasacerdote}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Hospital"
                          name="movilidadinformahospital"
                          value={values.movilidadinformahospital}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.movilidadinformahospital && errors.movilidadinformahospital ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.movilidadinformahospital}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Bienestar Social"
                          name="movilidadinformabienestar"
                          value={values.movilidadinformabienestar}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.movilidadinformabienestar && errors.movilidadinformabienestar ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.movilidadinformabienestar}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Policia"
                          name="movilidadinformapolicia"
                          value={values.movilidadinformapolicia}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.movilidadinformapolicia && errors.movilidadinformapolicia ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.movilidadinformapolicia}
                          </div>
                        ) : null}
                      </div>
                      {
                        values.movilidadinforma == "No" && (
                          <>
                            <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                              <h2 className="text-base font-bold leading-7 text-gray-900">
                                Si no informó, ¿por qué no lo hizo?
                              </h2>
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Select
                                label="No sabía a quién acudir"
                                name="movilidadnoinformanosabe"
                                value={values.movilidadnoinformanosabe}
                                onChange={handleChange}
                                array={SINO}
                              />
                              {touched.movilidadnoinformanosabe && errors.movilidadnoinformanosabe ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.movilidadnoinformanosabe}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Select
                                label="Había informado en casos de violencia anteriores, pero no recibí apoyo"
                                name="movilidadnoinformanoapoyo"
                                value={values.movilidadnoinformanoapoyo}
                                onChange={handleChange}
                                array={SINO}
                              />
                              {touched.movilidadnoinformanoapoyo && errors.movilidadnoinformanoapoyo ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.movilidadnoinformanoapoyo}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Select
                                label="No quiero perjudicar a mis familiares"
                                name="movilidadnoinformanoperjudicar"
                                value={values.movilidadnoinformanoperjudicar}
                                onChange={handleChange}
                                array={SINO}
                              />
                              {touched.movilidadnoinformanoperjudicar && errors.movilidadnoinformanoperjudicar ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.movilidadnoinformanoperjudicar}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Select
                                label="Empeoraría la situación"
                                name="movilidadnoinformaempeoraria"
                                value={values.movilidadnoinformaempeoraria}
                                onChange={handleChange}
                                array={SINO}
                              />
                              {touched.movilidadnoinformaempeoraria && errors.movilidadnoinformaempeoraria ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.movilidadnoinformaempeoraria}
                                </div>
                              ) : null}
                            </div>
                          </>
                        )
                      }
                    </>
                  )}
                </>
              )}
            </div>

            <div className="col-span-12 lg:col-span-6">
              <Select
                label="Deseas agregar algún comentario con respecto a esta denuncia."
                name="activarcomentario"
                value={values.activarcomentario}
                onChange={(e) => {
                  handleChange(e)
                }}
                array={SINO}
              />
            </div>

            {
              values.activarcomentario == "Si" && (
                <div className="col-span-12 lg:col-span-6">
                  <Textareas
                    label="Comentario"
                    name="comentariodenuncia"
                    placeholder="Escribe tu comentario"
                    value={values.comentariodenuncia}
                    onChange={handleChange}
                    type="text"
                  />
                  {touched.comentariodenuncia && errors.comentariodenuncia ? (
                    <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                      <ErrorIcon fontSize="small" /> {errors.comentariodenuncia}
                    </div>
                  ) : null}
                </div>
              )
            }

            <div className="flex justify-end mt-6 gap-5">
              <button
                type="button"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-claret-800 hover:bg-claret-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => {
                  navigation("/dinero");
                }}
              >
                Regresar
              </button>
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-claret-900 hover:bg-claret-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {
                  loading ? "Cargando..." : "Terminar"
                }
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
