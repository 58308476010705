/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Denunciante from "./Vistas/denunciante";

export default function Index() {
  
  return (
    <>
      <div className="p-2">
        <Denunciante />
      </div>
    </>
  );
}
