/* eslint-disable eqeqeq */
import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Input from "../../components/Input";
import Select from "../../components/Select";
import { SINO } from "../../utils/selectors";
import { useNavigate } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";

export default function Malno() {
  const navigation = useNavigate();
  const [values, setvalues] = React.useState({});
  const [familia, setFamilia] = React.useState();

  React.useEffect(() => {
    const malno = localStorage.getItem("malno");
    if (malno) {
      setvalues(JSON.parse(malno));
    }
    return () => { };
  }, []);

  React.useEffect(() => {
    const esFamilia = localStorage.getItem("familiar");
    setFamilia(esFamilia);
  }, []);

  return (
    <div>
      <Formik
        initialValues={{
          mal: values?.mal || "",
          malmeses: values?.malmeses || "",
          malhijos: values?.malhijos || "",
          malnietos: values?.malnietos || "",
          malpareja: values?.malpareja || "",
          malyernonuera: values?.malyernonuera || "",
          malotros: values?.malotros || "",
          maltestigo: values?.maltestigo || "",
          malintoxicado: values?.malintoxicado || "",
          maleconomico: values?.maleconomico || "",
          maldomicilio: values?.maldomicilio || "",
          maldefensa: values?.maldefensa || "",
          maldefensahijos: values?.maldefensahijos || "",
          maldefensanietos: values?.maldefensanietos || "",
          maldefensapareja: values?.maldefensapareja || "",
          maldefensayerno: values?.maldefensayerno || "",
          maldefensaotros: values?.maldefensaotros || "",
          malinforma: values?.malinforma || "",
          malinformafamiliares: values?.malinformafamiliares || "",
          malinformaamigos: values?.malinformaamigos || "",
          malinformasacerdote: values?.malinformasacerdote || "",
          malinformahospital: values?.malinformahospital || "",
          malinformabienestar: values?.malinformabienestar || "",
          malinformapolicia: values?.malinformapolicia || "",
          malnoinformanosabe: values?.malnoinformanosabe || "",
          malnoinformanoapoyo: values?.malnoinformanoapoyo || "",
          malnoinformanoperjudicar: values?.malnoinformanoperjudicar || "",
          malnoinformaempeoraria: values?.malnoinformaempeoraria || "",
        }}
        enableReinitialize={true}
        onSubmit={(values) => {
          localStorage.setItem("malno", JSON.stringify(values));
          navigation("/abandona");
        }}
        validationSchema={Yup.object({
          mal: Yup.string().required("Campo requerido"),
          malmeses: Yup.string().when("mal", {
            is: "Si",
            then: () => Yup.string().required("Campo requerido"),
          }),
          malhijos: Yup.string().when("mal", {
            is: "Si",
            then: () => Yup.string().required("Campo requerido"),
          }),
          malnietos: Yup.string().when("mal", {
            is: "Si",
            then: () => Yup.string().required("Campo requerido"),
          }),
          malpareja: Yup.string().when("mal", {
            is: "Si",
            then: () => Yup.string().required("Campo requerido"),
          }),
          malyernonuera: Yup.string().when("mal", {
            is: "Si",
            then: () => Yup.string().required("Campo requerido"),
          }),
          malotros: Yup.string().when("mal", {
            is: "Si",
            then: () => Yup.string().required("Campo requerido"),
          }),
          maltestigo: Yup.string().when("mal", {
            is: "Si",
            then: () => Yup.string().required("Campo requerido"),
          }),
          malintoxicado:
            familia === "Si" &&
            Yup.string().when("mal", {
              is: "Si",
              then: () => Yup.string().required("Campo requerido"),
            }),
          maleconomico:
            familia === "Si" &&
            Yup.string().when("mal", {
              is: "Si",
              then: () => Yup.string().required("Campo requerido"),
            }),
          maldomicilio:
            familia === "Si" &&
            Yup.string().when("mal", {
              is: "Si",
              then: () => Yup.string().required("Campo requerido"),
            }),
          maldefensa:
            familia == "Si" &&
            Yup.string().when("mal", {
              is: "Si",
              then: () => Yup.string().required("Campo requerido"),
            }),
          maldefensahijos:
            familia == "Si" &&
            Yup.string().when("mal", {
              is: "Si",
              then: () => Yup.string().when("maldefensa", {
                is: "Si",
                then: () => Yup.string().required("Campo requerido"),
              }),
            }),
          maldefensanietos:
            familia == "Si" &&
            Yup.string().when("mal", {
              is: "Si",
              then: () => Yup.string().when("maldefensa", {
                is: "Si",
                then: () => Yup.string().required("Campo requerido"),
              }),
            }),
          maldefensapareja:
            familia == "Si" &&
            Yup.string().when("mal", {
              is: "Si",
              then: () => Yup.string().when("maldefensa", {
                is: "Si",
                then: () => Yup.string().required("Campo requerido"),
              }),
            }),
          maldefensayerno:
            familia == "Si" &&
            Yup.string().when("mal", {
              is: "Si",
              then: () => Yup.string().when("maldefensa", {
                is: "Si",
                then: () => Yup.string().required("Campo requerido"),
              }),
            }),
          maldefensaotros:
            familia == "Si" &&
            Yup.string().when("mal", {
              is: "Si",
              then: () => Yup.string().when("maldefensa", {
                is: "Si",
                then: () => Yup.string().required("Campo requerido"),
              }),
            }),
          malinforma:
            familia === "Si" &&
            Yup.string().when("mal", {
              is: "Si",
              then: () => Yup.string().required("Campo requerido"),
            }),
          malinformafamiliares:
            familia === "Si" &&
            Yup.string().when("mal", {
              is: "Si",
              then: () => Yup.string().required("Campo requerido"),
            }),
          malinformaamigos:
            familia === "Si" &&
            Yup.string().when("mal", {
              is: "Si",
              then: () => Yup.string().required("Campo requerido"),
            }),
          malinformasacerdote:
            familia === "Si" &&
            Yup.string().when("mal", {
              is: "Si",
              then: () => Yup.string().required("Campo requerido"),
            }),
          malinformahospital:
            familia === "Si" &&
            Yup.string().when("mal", {
              is: "Si",
              then: () => Yup.string().required("Campo requerido"),
            }),
          malinformabienestar:
            familia === "Si" &&
            Yup.string().when("mal", {
              is: "Si",
              then: () => Yup.string().required("Campo requerido"),
            }),
          malinformapolicia:
            familia === "Si" &&
            Yup.string().when("mal", {
              is: "Si",
              then: () => Yup.string().required("Campo requerido"),
            }),
          malnoinformanosabe:
            familia == "Si" && Yup.string()
              .when(["mal", "malinforma"], {
                is: (mal, malinforma) => mal == "Si" && malinforma == "No",
                then: () => Yup.string().required("Campo requerido"),
              }),
          malnoinformanoapoyo:
            familia == "Si" && Yup.string()
              .when(["mal", "malinforma"], {
                is: (mal, malinforma) => mal == "Si" && malinforma == "No",
                then: () => Yup.string().required("Campo requerido"),
              }),
          malnoinformanoperjudicar:
            familia == "Si" && Yup.string()
              .when(["mal", "malinforma"], {
                is: (mal, malinforma) => mal == "Si" && malinforma == "No",
                then: () => Yup.string().required("Campo requerido"),
              }),
          malnoinformaempeoraria:
            familia == "Si" && Yup.string()
              .when(["mal", "malinforma"], {
                is: (mal, malinforma) => mal == "Si" && malinforma == "No",
                then: () => Yup.string().required("Campo requerido"),
              })
        })}
      >
        {({ values, touched, errors, handleChange, setFieldValue, resetForm }) => (
          <Form>
            <div className="grid grid-cols-1 gap-y-6 md:grid-cols-12 gap-x-6 p-2">
              <div className="col-span-12">
                <Select
                  label="2.  Algunos de sus familiares directos/personas cercanas a La PAM... ¿no le hablan y/o no le apoyan, o lo/la dejan solo/a por mucho tiempo?"
                  name="mal"
                  value={values.mal}
                  onChange={(e) => {
                    handleChange(e);
                    if (e.target.value === "No") {
                      const mal = e.target.value;
                      setvalues({
                        mal: mal,
                        malmeses: "",
                        malhijos: "",
                        malnietos: "",
                        malpareja: "",
                        malyernonuera: "",
                        malotros: "",
                        maltestigo: "",
                        malintoxicado: "",
                        maleconomico: "",
                        maldomicilio: "",
                        maldefensa: "",
                        maldefensahijos: "",
                        maldefensanietos: "",
                        maldefensapareja: "",
                        maldefensayerno: "",
                        maldefensaotros: "",
                        malinforma: "",
                        malinformafamiliares: "",
                        malinformaamigos: "",
                        malinformasacerdote: "",
                        malinformahospital: "",
                        malinformabienestar: "",
                        malinformapolicia: "",
                        malnoinformanosabe: "",
                        malnoinformanoapoyo: "",
                        malnoinformanoperjudicar: "",
                        malnoinformaempeoraria: "",
                      });
                      resetForm({
                        values: {
                          mal: mal,
                          malmeses: "",
                          malhijos: "",
                          malnietos: "",
                          malpareja: "",
                          malyernonuera: "",
                          malotros: "",
                          maltestigo: "",
                          malintoxicado: "",
                          maleconomico: "",
                          maldomicilio: "",
                          maldefensa: "",
                          maldefensahijos: "",
                          maldefensanietos: "",
                          maldefensapareja: "",
                          maldefensayerno: "",
                          maldefensaotros: "",
                          malinforma: "",
                          malinformafamiliares: "",
                          malinformaamigos: "",
                          malinformasacerdote: "",
                          malinformahospital: "",
                          malinformabienestar: "",
                          malinformapolicia: "",
                          malnoinformanosabe: "",
                          malnoinformanoapoyo: "",
                          malnoinformanoperjudicar: "",
                          malnoinformaempeoraria: "",
                        },
                      });
                    }
                  }}
                  array={SINO}
                />
                {touched.mal && errors.mal ? (
                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                    <ErrorIcon fontSize="small" /> {errors.mal}
                  </div>
                ) : null}
              </div>
              {values.mal === "Si" && (
                <>
                  <div className="col-span-12">
                    <Input
                      label="2.2 ¿Desde hace cuánto tiempo ocurre esto? (indica con palabras, si años, meses o días)"
                      name="malmeses"
                      type="text"
                      placeholder="Inserta cuanto tiempo"
                      value={values.malmeses}
                      onChange={handleChange}
                    />
                    {touched.malmeses && errors.malmeses ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.malmeses}
                      </div>
                    ) : null}
                  </div>
                  <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                    <h2 className="text-base font-bold leading-7 text-gray-900">
                      ¿Quién lo hizo y cuántas veces ocurrió en el último mes?
                    </h2>
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Hijos/Hijas"
                      name="malhijos"
                      placeholder="Hijos"
                      value={values.malhijos}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.malhijos && errors.malhijos ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.malhijos}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Nietos/Nietas"
                      placeholder="Nietos"
                      name="malnietos"
                      value={values.malnietos}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.malnietos && errors.malnietos ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.malnietos}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Pareja"
                      name="malpareja"
                      placeholder="Pareja"
                      value={values.malpareja}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.malpareja && errors.malpareja ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.malpareja}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Yerno/Nuera"
                      name="malyernonuera"
                      placeholder="Yerno/Nuera"
                      value={values.malyernonuera}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.malyernonuera && errors.malyernonuera ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.malyernonuera}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Otros"
                      name="malotros"
                      placeholder="Otros"
                      value={values.malotros}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.malotros && errors.malotros ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.malotros}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Select
                      label="No sabe, pero lo atestiguó"
                      name="maltestigo"
                      value={values.maltestigo}
                      onChange={handleChange}
                      array={SINO}
                    />
                    {touched.maltestigo && errors.maltestigo ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.maltestigo}
                      </div>
                    ) : null}
                  </div>
                  {familia === "Si" && (
                    <>
                      <div className="border-t col-span-12"></div>
                      <div className="col-span-12">
                        <Select
                          label="2.3 ¿Estos episodios ocurren cuando el agresor se encuentra intoxicado (tomó alcohol, alguna droga)?"
                          name="malintoxicado"
                          value={values.malintoxicado}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.malintoxicado && errors.malintoxicado ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.malintoxicado}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="¿El/la/los agresores viven en el mismo domicilio que la PAM?"
                          name="maldomicilio"
                          value={values.maldomicilio}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.maldomicilio && errors.maldomicilio ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.maldomicilio}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="¿Estos episodios ocurren cuando el agresor tiene problemas económicos?"
                          name="maleconomico"
                          value={values.maleconomico}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.maleconomico && errors.maleconomico ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.maleconomico}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-12">
                        <Select
                          label="Cuando estos episodios ocurren, ¿alguien entra en su defensa?"
                          name="maldefensa"
                          value={values.maldefensa}
                          onChange={(e) => {
                            handleChange(e);
                            if (e.target.value === "No") {
                              setFieldValue('maldefensahijos', "")
                              setFieldValue('maldefensanietos', "")
                              setFieldValue('maldefensapareja', "")
                              setFieldValue('maldefensayerno', "")
                              setFieldValue('maldefensaotros', "")
                            }
                          }}
                          array={SINO}
                        />
                        {touched.maldefensa && errors.maldefensa ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.maldefensa}
                          </div>
                        ) : null}
                      </div>
                      {values.maldefensa == "Si" && (
                        <>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Hijos/Hijas"
                              name="maldefensahijos"
                              placeholder="Hijos"
                              value={values.maldefensahijos}
                              onChange={handleChange}
                              type="text"
                            />
                            {touched.maldefensahijos && errors.maldefensahijos ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.maldefensahijos}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Nietos/Nietas"
                              name="maldefensanietos"
                              placeholder="Nietos"
                              value={values.maldefensanietos}
                              onChange={handleChange}
                              type="text"
                            />
                            {touched.maldefensanietos && errors.maldefensanietos ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.maldefensanietos}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Pareja/esposo/esposa"
                              name="maldefensapareja"
                              placeholder="Pareja/esposo/esposa"
                              value={values.maldefensapareja}
                              onChange={handleChange}
                              type="text"
                            />
                            {touched.maldefensapareja && errors.maldefensapareja ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.maldefensapareja}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Yerno/Nuera"
                              name="maldefensayerno"
                              placeholder="Yerno"
                              value={values.maldefensayerno}
                              onChange={handleChange}
                              type="text"
                            />
                            {touched.maldefensayerno && errors.maldefensayerno ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.maldefensayerno}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Otras personas que no son familiares directos"
                              name="maldefensaotros"
                              placeholder="Otros"
                              value={values.maldefensaotros}
                              onChange={handleChange}
                              type="text"
                            />
                            {touched.maldefensaotros && errors.maldefensaotros ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.maldefensaotros}
                              </div>
                            ) : null}
                          </div>
                        </>
                      )}
                      <div className="border-t col-span-12"></div>
                      <div className="col-span-12">
                        <Select
                          label="2.4 Antes de acudir al DIF, ¿La PAM a quién informó de esta situación? (indique todos los casos que apliquen)"
                          name="malinforma"
                          value={values.malinforma}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.malinforma && errors.malinforma ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.malinforma}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Familiares"
                          name="malinformafamiliares"
                          value={values.malinformafamiliares}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.malinformafamiliares && errors.malinformafamiliares ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.malinformafamiliares}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Amigos"
                          name="malinformaamigos"
                          value={values.malinformaamigos}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.malinformaamigos && errors.malinformaamigos ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.malinformaamigos}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Sacerdote"
                          name="malinformasacerdote"
                          value={values.malinformasacerdote}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.malinformasacerdote && errors.malinformasacerdote ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.malinformasacerdote}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Hospital"
                          name="malinformahospital"
                          value={values.malinformahospital}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.malinformahospital && errors.malinformahospital ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.malinformahospital}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Bienestar Social"
                          name="malinformabienestar"
                          value={values.malinformabienestar}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.malinformabienestar && errors.malinformabienestar ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.malinformabienestar}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Policia"
                          name="malinformapolicia"
                          value={values.malinformapolicia}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.malinformapolicia && errors.malinformapolicia ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.malinformapolicia}
                          </div>
                        ) : null}
                      </div>
                      {values.malinforma === "No" && (
                        <>
                          <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                            <h2 className="text-base font-bold leading-7 text-gray-900">
                              Si no informó, ¿por qué no lo hizo?
                            </h2>
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Select
                              label="No sabía a quién acudir"
                              name="malnoinformanosabe"
                              value={values.malnoinformanosabe}
                              onChange={handleChange}
                              array={SINO}
                            />
                            {touched.malnoinformanosabe && errors.malnoinformanosabe ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.malnoinformanosabe}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Select
                              label="Había informado en casos de violencia anteriores, pero no recibí apoyo"
                              name="malnoinformanoapoyo"
                              value={values.malnoinformanoapoyo}
                              onChange={handleChange}
                              array={SINO}
                            />
                            {touched.malnoinformanoapoyo && errors.malnoinformanoapoyo ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.malnoinformanoapoyo}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Select
                              label="No quiero perjudicar a mis familiares"
                              name="malnoinformanoperjudicar"
                              value={values.malnoinformanoperjudicar}
                              onChange={handleChange}
                              array={SINO}
                            />
                            {touched.malnoinformanoperjudicar && errors.malnoinformanoperjudicar ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.malnoinformanoperjudicar}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Select
                              label="Empeoraría la situación"
                              name="malnoinformaempeoraria"
                              value={values.malnoinformaempeoraria}
                              onChange={handleChange}
                              array={SINO}
                            />
                            {touched.malnoinformaempeoraria && errors.malnoinformaempeoraria ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.malnoinformaempeoraria}
                              </div>
                            ) : null}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
            <div className="flex justify-end mt-6 gap-5">
              <button
                type="button"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-claret-800 hover:bg-claret-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => {
                  navigation("/malno");
                }}
              >
                Regresar
              </button>
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-claret-900 hover:bg-claret-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Siguiente
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
