/* eslint-disable eqeqeq */
import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Input from "../../components/Input";
import Select from "../../components/Select";
import { SINO } from "../../utils/selectors";
import { useNavigate } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";

export default function Dinero() {
  const navigation = useNavigate();
  const [values, setvalues] = React.useState({});
  const [familia, setFamilia] = React.useState();

  React.useEffect(() => {
    const dinero = localStorage.getItem("dinero");
    if (dinero) {
      setvalues(JSON.parse(dinero));
    }
    return () => { };
  }, []);

  React.useEffect(() => {
    const esFamilia = localStorage.getItem("familiar");
    setFamilia(esFamilia);
  }, []);

  const validateSchema = Yup.object({
    dinero: Yup.string().required("Campo requerido"),
    dineromeses: Yup.string().when("dinero", {
      is: "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    dinerohijos: Yup.string().when("dinero", {
      is: "Si",
      then: () => Yup.number().typeError('Sólo números').required("Campo requerido"),
    }),
    dineronietos: Yup.string().when("dinero", {
      is: "Si",
      then: () => Yup.number().typeError('Sólo números').required("Campo requerido"),
    }),
    dineropareja: Yup.string().when("dinero", {
      is: "Si",
      then: () => Yup.number().typeError('Sólo números').required("Campo requerido"),
    }),
    dineroyernonuera: Yup.string().when("dinero", {
      is: "Si",
      then: () => Yup.number().typeError('Sólo números').required("Campo requerido"),
    }),
    dinerootros: Yup.string().when("dinero", {
      is: "Si",
      then: () => Yup.number().typeError('Sólo números').required("Campo requerido"),
    }),
    dinerotestigo: Yup.string().when("dinero", {
      is: "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    dinerointoxicado:
      familia === "Si" &&
      Yup.string().when("dinero", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    dinerodomicilio:
      familia === "Si" &&
      Yup.string().when("dinero", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    dineroeconomico:
      familia === "Si" &&
      Yup.string().when("dinero", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    dinerodefensa:
      familia == "Si" &&
      Yup.string().when("dinero", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    dinerodefensahijos:
      familia == "Si" &&
      Yup.string().when("dinero", {
        is: "Si",
        then: () => Yup.string().when("dinerodefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    dinerodefensanietos:
      familia == "Si" &&
      Yup.string().when("dinero", {
        is: "Si",
        then: () => Yup.string().when("dinerodefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    dinerodefensapareja:
      familia == "Si" &&
      Yup.string().when("dinero", {
        is: "Si",
        then: () => Yup.string().when("dinerodefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    dinerodefensayerno:
      familia == "Si" &&
      Yup.string().when("dinero", {
        is: "Si",
        then: () => Yup.string().when("dinerodefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    dinerodefensaotros:
      familia == "Si" &&
      Yup.string().when("dinero", {
        is: "Si",
        then: () => Yup.string().when("dinerodefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    dineroinforma:
      familia === "Si" &&
      Yup.string().when("dinero", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    dineroinformafamiliares:
      familia === "Si" &&
      Yup.string().when("dinero", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    dineroinformaamigos:
      familia === "Si" &&
      Yup.string().when("dinero", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    dineroinformasacerdote:
      familia === "Si" &&
      Yup.string().when("dinero", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    dineroinformahospital:
      familia === "Si" &&
      Yup.string().when("dinero", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    dineroinformabienestar:
      familia === "Si" &&
      Yup.string().when("dinero", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    dineroinformapolicia:
      familia === "Si" &&
      Yup.string().when("dinero", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    dineronoinformanosabe:
      familia == "Si" && Yup.string()
        .when(["dinero", "dineroinforma"], {
          is: (dinero, dineroinforma) => dinero == "Si" && dineroinforma == "No",
          then: () => Yup.string().required("Campo requerido"),
        }),
    dineronoinformanoapoyo:
      familia == "Si" && Yup.string()
        .when(["dinero", "dineroinforma"], {
          is: (dinero, dineroinforma) => dinero == "Si" && dineroinforma == "No",
          then: () => Yup.string().required("Campo requerido"),
        }),
    dineronoinformanoperjudicar:
      familia == "Si" && Yup.string()
        .when(["dinero", "dineroinforma"], {
          is: (dinero, dineroinforma) => dinero == "Si" && dineroinforma == "No",
          then: () => Yup.string().required("Campo requerido"),
        }),
    dineronoinformaempeoraria:
      familia == "Si" && Yup.string()
        .when(["dinero", "dineroinforma"], {
          is: (dinero, dineroinforma) => dinero == "Si" && dineroinforma == "No",
          then: () => Yup.string().required("Campo requerido"),
        })
  })

  return (
    <div>
      <Formik
        initialValues={{
          dinero: values?.dinero || "",
          dineromeses: values?.dineromeses || "",
          dinerohijos: values?.dinerohijos || "",
          dineronietos: values?.dineronietos || "",
          dineropareja: values?.dineropareja || "",
          dineroyernonuera: values?.dineroyernonuera || "",
          dinerootros: values?.dinerootros || "",
          dinerotestigo: values?.dinerotestigo || "",
          dinerointoxicado: values?.dinerointoxicado || "",
          dineroeconomico: values?.dineroeconomico || "",
          dinerodomicilio: values?.dinerodomicilio || "",
          dinerodefensa: values?.dinerodefensa || "",
          dinerodefensahijos: values?.dinerodefensahijos || "",
          dinerodefensanietos: values?.dinerodefensanietos || "",
          dinerodefensapareja: values?.dinerodefensapareja || "",
          dinerodefensayerno: values?.dinerodefensayerno || "",
          dinerodefensaotros: values?.dinerodefensaotros || "",
          dineroinforma: values?.dineroinforma || "",
          dineroinformafamiliares: values?.dineroinformafamiliares || "",
          dineroinformaamigos: values?.dineroinformaamigos || "",
          dineroinformasacerdote: values?.dineroinformasacerdote || "",
          dineroinformahospital: values?.dineroinformahospital || "",
          dineroinformabienestar: values?.dineroinformabienestar || "",
          dineroinformapolicia: values?.dineroinformapolicia || "",
          dineronoinformanosabe: values?.dineronoinformanosabe || "",
          dineronoinformanoapoyo: values?.dineronoinformanoapoyo || "",
          dineronoinformanoperjudicar:
            values?.dineronoinformanoperjudicar || "",
          dineronoinformaempeoraria: values?.dineronoinformaempeoraria || "",
        }}
        enableReinitialize={true}
        onSubmit={(values) => {
          localStorage.setItem("dinero", JSON.stringify(values));
          navigation("/movilidad");
        }}
        validationSchema={validateSchema}
      >
        {({ values, handleChange, resetForm, setFieldValue, touched, errors }) => (
          <Form>
            <div className="grid grid-cols-1 gap-y-6 md:grid-cols-12 gap-x-6 p-2">
              <div className="col-span-12">
                <Select
                  label="6. ¿Alguien utilizó/tomó su dinero sin su consentimiento y/o la/lo despojó de algún bien inmueble (casa, locales, terrenos)?"
                  name="dinero"
                  value={values.dinero}
                  onChange={(e) => {
                    handleChange(e);
                    if (e.target.value === "No") {
                      const dinero = e.target.value;
                      setvalues({
                        dinero: dinero,
                        dineromeses: "",
                        dinerohijos: "",
                        dineronietos: "",
                        dineropareja: "",
                        dineroyernonuera: "",
                        dinerootros: "",
                        dinerotestigo: "",
                        dinerointoxicado: "",
                        dineroeconomico: "",
                        dinerodomicilio: "",
                        dinerodefensa: "",
                        dinerodefensahijos: "",
                        dinerodefensanietos: "",
                        dinerodefensapareja: "",
                        dinerodefensayerno: "",
                        dinerodefensaotros: "",
                        dineroinforma: "",
                        dineroinformafamiliares: "",
                        dineroinformaamigos: "",
                        dineroinformasacerdote: "",
                        dineroinformahospital: "",
                        dineroinformabienestar: "",
                        dineroinformapolicia: "",
                        dineronoinformanosabe: "",
                        dineronoinformanoapoyo: "",
                        dineronoinformanoperjudicar: "",
                        dineronoinformaempeoraria: "",
                      });
                      resetForm({
                        values: {
                          dinero: dinero,
                          dineromeses: "",
                          dinerohijos: "",
                          dineronietos: "",
                          dineropareja: "",
                          dineroyernonuera: "",
                          dinerootros: "",
                          dinerotestigo: "",
                          dinerointoxicado: "",
                          dineroeconomico: "",
                          dinerodomicilio: "",
                          dinerodefensa: "",
                          dinerodefensahijos: "",
                          dinerodefensanietos: "",
                          dinerodefensapareja: "",
                          dinerodefensayerno: "",
                          dinerodefensaotros: "",
                          dineroinforma: "",
                          dineroinformafamiliares: "",
                          dineroinformaamigos: "",
                          dineroinformasacerdote: "",
                          dineroinformahospital: "",
                          dineroinformabienestar: "",
                          dineroinformapolicia: "",
                          dineronoinformanosabe: "",
                          dineronoinformanoapoyo: "",
                          dineronoinformanoperjudicar: "",
                          dineronoinformaempeoraria: "",
                        },
                      });
                    }
                  }}
                  array={SINO}
                />
                {touched.dinero && errors.dinero ? (
                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                    <ErrorIcon fontSize="small" /> {errors.dinero}
                  </div>
                ) : null}
              </div>
              {values.dinero === "Si" && (
                <>
                  <div className="col-span-12">
                    <Input
                      label="6.2 ¿Desde hace cuánto tiempo ocurre esto? (indica con palabras, si años, meses o días)"
                      name="dineromeses"
                      placeholder="Inserta cuanto tiempo"
                      type="text"
                      value={values.dineromeses}
                      onChange={handleChange}
                    />
                    {touched.dineromeses && errors.dineromeses ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.dineromeses}
                      </div>
                    ) : null}
                  </div>
                  <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                    <h2 className="text-base font-bold leading-7 text-gray-900">
                      ¿Quién lo hizo y cuántas veces ocurrió en el último mes?
                    </h2>
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Hijos/Hijas"
                      name="dinerohijos"
                      placeholder="Hijos"
                      value={values.dinerohijos}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.dinerohijos && errors.dinerohijos ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.dinerohijos}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Nietos/Nietas"
                      name="dineronietos"
                      placeholder="Nietos"
                      value={values.dineronietos}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.dineronietos && errors.dineronietos ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.dineronietos}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Pareja"
                      name="dineropareja"
                      placeholder="Pareja"
                      value={values.dineropareja}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.dineropareja && errors.dineropareja ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.dineropareja}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Yerno/Nuera"
                      name="dineroyernonuera"
                      placeholder="Yerno/Nuera"
                      value={values.dineroyernonuera}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.dineroyernonuera && errors.dineroyernonuera ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.dineroyernonuera}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Otros"
                      name="dinerootros"
                      placeholder="Otros"
                      value={values.dinerootros}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.dinerootros && errors.dinerootros ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.dinerootros}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Select
                      label="No sabe, pero lo atestiguó"
                      name="dinerotestigo"
                      value={values.dinerotestigo}
                      onChange={handleChange}
                      array={SINO}
                    />
                    {touched.dinerotestigo && errors.dinerotestigo ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.dinerotestigo}
                      </div>
                    ) : null}
                  </div>
                  {familia === "Si" && (
                    <>
                      <div className="border-t col-span-12"></div>
                      <div className="col-span-12">
                        <Select
                          label="6.3 ¿El uso/sustracción de recursos y/o el despojo de bienes inmuebles ocurre cuando la persona se encuentra con problemas económicos?"
                          name="dinerointoxicado"
                          value={values.dinerointoxicado}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.dinerointoxicado && errors.dinerointoxicado ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.dinerointoxicado}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="¿El/la/las personas que usan/toman su dinero y/o lo despojan de bienes inmuebles comparten el domicilio que la PAM objeto de violencia?"
                          name="dinerodomicilio"
                          value={values.dinerodomicilio}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.dinerodomicilio && errors.dinerodomicilio ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.dinerodomicilio}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="¿Estos episodios ocurren cuando el agresor tiene problemas económicos?"
                          name="dineroeconomico"
                          value={values.dineroeconomico}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.dineroeconomico && errors.dineroeconomico ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.dineroeconomico}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-12">
                        <Select
                          label="Cuando estos episodios ocurren, ¿alguien entra en su defensa?"
                          name="dinerodefensa"
                          value={values.dinerodefensa}
                          onChange={(e) => {
                            handleChange(e);
                            if (e.target.value === "No") {
                              setFieldValue('dinerodefensahijos', "")
                              setFieldValue('dinerodefensanietos', "")
                              setFieldValue('dinerodefensapareja', "")
                              setFieldValue('dinerodefensayerno', "")
                              setFieldValue('dinerodefensaotros', "")
                            }
                          }}
                          array={SINO}
                        />
                        {touched.dinerodefensa && errors.dinerodefensa ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.dinerodefensa}
                          </div>
                        ) : null}
                      </div>
                      {values.dinerodefensa == "Si" && (
                        <>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Hijos/Hijas"
                              name="dinerodefensahijos"
                              placeholder="Hijos"
                              value={values.dinerodefensahijos}
                              onChange={handleChange}
                              type="text"
                            />
                            {touched.dinerodefensahijos && errors.dinerodefensahijos ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.dinerodefensahijos}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Nietos/Nietas"
                              name="dinerodefensanietos"
                              placeholder="Nietos"
                              value={values.dinerodefensanietos}
                              onChange={handleChange}
                              type="text"
                            />
                            {touched.dinerodefensanietos && errors.dinerodefensanietos ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.dinerodefensanietos}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Pareja/esposo/esposa"
                              name="dinerodefensapareja"
                              placeholder="Pareja/esposo/esposa"
                              value={values.dinerodefensapareja}
                              onChange={handleChange}
                              type="text"
                            />
                            {touched.dinerodefensapareja && errors.dinerodefensapareja ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.dinerodefensapareja}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Yerno/Nuera"
                              name="dinerodefensayerno"
                              placeholder="Yerno"
                              value={values.dinerodefensayerno}
                              onChange={handleChange}
                              type="text"
                            />
                            {touched.dinerodefensayerno && errors.dinerodefensayerno ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.dinerodefensayerno}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Otras personas que no son familiares directos"
                              name="dinerodefensaotros"
                              placeholder="Otros"
                              value={values.dinerodefensaotros}
                              onChange={handleChange}
                              type="text"
                            />
                            {touched.dinerodefensaotros && errors.dinerodefensaotros ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.dinerodefensaotros}
                              </div>
                            ) : null}
                          </div>
                        </>
                      )}
                      <div className="border-t col-span-12"></div>
                      <div className="col-span-12">
                        <Select
                          label="6.4 En caso hayan utilizado estos recursos sin su consentimiento, ¿La PAM ha informado estos hechos a alguna persona? (indique todos los casos que apliquen)"
                          name="dineroinforma"
                          value={values.dineroinforma}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.dineroinforma && errors.dineroinforma ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.dineroinforma}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Familiares"
                          name="dineroinformafamiliares"
                          value={values.dineroinformafamiliares}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.dineroinformafamiliares && errors.dineroinformafamiliares ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.dineroinformafamiliares}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Amigos"
                          name="dineroinformaamigos"
                          value={values.dineroinformaamigos}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.dineroinformaamigos && errors.dineroinformaamigos ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.dineroinformaamigos}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Sacerdote"
                          name="dineroinformasacerdote"
                          value={values.dineroinformasacerdote}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.dineroinformasacerdote && errors.dineroinformasacerdote ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.dineroinformasacerdote}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Hospital"
                          name="dineroinformahospital"
                          value={values.dineroinformahospital}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.dineroinformahospital && errors.dineroinformahospital ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.dineroinformahospital}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Bienestar Social"
                          name="dineroinformabienestar"
                          value={values.dineroinformabienestar}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.dineroinformabienestar && errors.dineroinformabienestar ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.dineroinformabienestar}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Policia"
                          name="dineroinformapolicia"
                          value={values.dineroinformapolicia}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.dineroinformapolicia && errors.dineroinformapolicia ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.dineroinformapolicia}
                          </div>
                        ) : null}
                      </div>
                      {
                        values.dineroinforma == "No" && (
                          <>
                            <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                              <h2 className="text-base font-bold leading-7 text-gray-900">
                                Si no informó, ¿por qué no lo hizo?
                              </h2>
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Select
                                label="No sabía a quién acudir"
                                name="dineronoinformanosabe"
                                value={values.dineronoinformanosabe}
                                onChange={handleChange}
                                array={SINO}
                              />
                              {touched.dineronoinformanosabe && errors.dineronoinformanosabe ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.dineronoinformanosabe}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Select
                                label="Había informado en casos de violencia anteriores, pero no recibí apoyo"
                                name="dineronoinformanoapoyo"
                                value={values.dineronoinformanoapoyo}
                                onChange={handleChange}
                                array={SINO}
                              />
                              {touched.dineronoinformanoapoyo && errors.dineronoinformanoapoyo ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.dineronoinformanoapoyo}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Select
                                label="No quiero perjudicar a mis familiares"
                                name="dineronoinformanoperjudicar"
                                value={values.dineronoinformanoperjudicar}
                                onChange={handleChange}
                                array={SINO}
                              />
                              {touched.dineronoinformanoperjudicar && errors.dineronoinformanoperjudicar ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.dineronoinformanoperjudicar}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Select
                                label="Empeoraría la situación"
                                name="dineronoinformaempeoraria"
                                value={values.dineronoinformaempeoraria}
                                onChange={handleChange}
                                array={SINO}
                              />
                              {touched.dineronoinformaempeoraria && errors.dineronoinformaempeoraria ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.dineronoinformaempeoraria}
                                </div>
                              ) : null}
                            </div>
                          </>
                        )
                      }
                    </>
                  )}
                </>
              )}
            </div>
            <div className="flex justify-end mt-6 gap-5">
              <button
                type="button"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-claret-800 hover:bg-claret-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => {
                  navigation("/dinero");
                }}
              >
                Regresar
              </button>
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-claret-900 hover:bg-claret-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Siguiente
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
