import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Index from "./pages";
import Pam from "./pages/Vistas/pam";
import Vf from "./pages/Vistas/vf";
import Malno from "./pages/Vistas/malno";
import Abandona from "./pages/Vistas/abandona";
import Vp from "./pages/Vistas/vp";
import Sexual from "./pages/Vistas/sexual";
import Dinero from "./pages/Vistas/dinero";
import PageHeader from "./components/PageHeader";
import Movilidad from './pages/Vistas/movilidad'
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <PageHeader />
      <div className="max-w-7xl mx-auto py-6">
        <Routes>
          <Route path="/" exact element={<Index />} />
          <Route path="/pam" exact element={<Pam />} />
          <Route path="/vf" exact element={<Vf />} />
          <Route path="/malno" exact element={<Malno />} />
          <Route path="/abandona" exact element={<Abandona />} />
          <Route path="/vp" exact element={<Vp />} />
          <Route path="/sexual" exact element={<Sexual />} />
          <Route path="/dinero" exact element={<Dinero />} />
          <Route path="/movilidad" exact element={<Movilidad />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
