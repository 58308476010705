/* eslint-disable eqeqeq */
import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Input from "../../components/Input";
import Select from "../../components/Select";
import { SINO } from "../../utils/selectors";
import { useNavigate } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";

export default function Sexual() {
  const navigation = useNavigate();
  const [values, setvalues] = React.useState({});
  const [familia, setFamilia] = React.useState();

  React.useEffect(() => {
    const sexual = localStorage.getItem("sexual");
    if (sexual) {
      setvalues(JSON.parse(sexual));
    }
    return () => { };
  }, []);

  React.useEffect(() => {
    const esFamilia = localStorage.getItem("familiar");
    setFamilia(esFamilia);
  }, []);

  const validateSchema = Yup.object({
    sexual: Yup.string().required("Campo requerido"),
    sexualmeses: Yup.string().when("sexual", {
      is: "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    sexualhijos: Yup.string().when("sexual", {
      is: "Si",
      then: () => Yup.number().typeError('Sólo números').required("Campo requerido"),
    }),
    sexualnietos: Yup.string().when("sexual", {
      is: "Si",
      then: () => Yup.number().typeError('Sólo números').required("Campo requerido"),
    }),
    sexualpareja: Yup.string().when("sexual", {
      is: "Si",
      then: () => Yup.number().typeError('Sólo números').required("Campo requerido"),
    }),
    sexualyernonuera: Yup.string().when("sexual", {
      is: "Si",
      then: () => Yup.number().typeError('Sólo números').required("Campo requerido"),
    }),
    sexualotros: Yup.string().when("sexual", {
      is: "Si",
      then: () => Yup.number().typeError('Sólo números').required("Campo requerido"),
    }),
    sexualtestigo: Yup.string().when("sexual", {
      is: "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    sexualintoxicado:
      familia === "Si" &&
      Yup.string().when("sexual", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    sexualdomicilio:
      familia === "Si" &&
      Yup.string().when("sexual", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    sexualeconomico:
      familia === "Si" &&
      Yup.string().when("sexual", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    sexualdefensa:
      familia == "Si" &&
      Yup.string().when("sexual", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    sexualdefensahijos:
      familia == "Si" &&
      Yup.string().when("sexual", {
        is: "Si",
        then: () => Yup.string().when("sexualdefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    sexualdefensanietos:
      familia == "Si" &&
      Yup.string().when("sexual", {
        is: "Si",
        then: () => Yup.string().when("sexualdefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    sexualdefensapareja:
      familia == "Si" &&
      Yup.string().when("sexual", {
        is: "Si",
        then: () => Yup.string().when("sexualdefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    sexualdefensayerno:
      familia == "Si" &&
      Yup.string().when("sexual", {
        is: "Si",
        then: () => Yup.string().when("sexualdefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    sexualdefensaotros:
      familia == "Si" &&
      Yup.string().when("sexual", {
        is: "Si",
        then: () => Yup.string().when("sexualdefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    sexualinforma:
      familia === "Si" &&
      Yup.string().when("sexual", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    sexualinformafamiliares:
      familia === "Si" &&
      Yup.string().when("sexual", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    sexualinformaamigos:
      familia === "Si" &&
      Yup.string().when("sexual", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    sexualinformasacerdote:
      familia === "Si" &&
      Yup.string().when("sexual", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    sexualinformahospital:
      familia === "Si" &&
      Yup.string().when("sexual", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    sexualinformabienestar:
      familia === "Si" &&
      Yup.string().when("sexual", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    sexualinformapolicia:
      familia === "Si" &&
      Yup.string().when("sexual", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    sexualnoinformanosabe:
      familia == "Si" && Yup.string()
        .when(["sexual", "sexualinforma"], {
          is: (sexual, sexualinforma) => sexual == "Si" && sexualinforma == "No",
          then: () => Yup.string().required("Campo requerido"),
        }),
    sexualnoinformanoapoyo:
      familia == "Si" && Yup.string()
        .when(["sexual", "sexualinforma"], {
          is: (sexual, sexualinforma) => sexual == "Si" && sexualinforma == "No",
          then: () => Yup.string().required("Campo requerido"),
        }),
    sexualnoinformanoperjudicar:
      familia == "Si" && Yup.string()
        .when(["sexual", "sexualinforma"], {
          is: (sexual, sexualinforma) => sexual == "Si" && sexualinforma == "No",
          then: () => Yup.string().required("Campo requerido"),
        }),
    sexualnoinformaempeoraria:
      familia == "Si" && Yup.string()
        .when(["sexual", "sexualinforma"], {
          is: (sexual, sexualinforma) => sexual == "Si" && sexualinforma == "No",
          then: () => Yup.string().required("Campo requerido"),
        })
  })

  return (
    <div>
      <Formik
        initialValues={{
          sexual: values?.sexual || "",
          sexualmeses: values?.sexualmeses || "",
          sexualhijos: values?.sexualhijos || "",
          sexualnietos: values?.sexualnietos || "",
          sexualpareja: values?.sexualpareja || "",
          sexualyernonuera: values?.sexualyernonuera || "",
          sexualotros: values?.sexualotros || "",
          sexualtestigo: values?.sexualtestigo || "",
          sexualintoxicado: values?.sexualintoxicado || "",
          sexualeconomico: values?.sexualeconomico || "",
          sexualdomicilio: values?.sexualdomicilio || "",
          sexualdefensa: values?.sexualdefensa || "",
          sexualdefensahijos: values?.sexualdefensahijos || "",
          sexualdefensanietos: values?.sexualdefensanietos || "",
          sexualdefensapareja: values?.sexualdefensapareja || "",
          sexualdefensayerno: values?.sexualdefensayerno || "",
          sexualdefensaotros: values?.sexualdefensaotros || "",
          sexualinforma: values?.sexualinforma || "",
          sexualinformafamiliares: values?.sexualinformafamiliares || "",
          sexualinformaamigos: values?.sexualinformaamigos || "",
          sexualinformasacerdote: values?.sexualinformasacerdote || "",
          sexualinformahospital: values?.sexualinformahospital || "",
          sexualinformabienestar: values?.sexualinformabienestar || "",
          sexualinformapolicia: values?.sexualinformapolicia || "",
          sexualnoinformanosabe: values?.sexualnoinformanosabe || "",
          sexualnoinformanoapoyo: values?.sexualnoinformanoapoyo || "",
          sexualnoinformanoperjudicar:
            values?.sexualnoinformanoperjudicar || "",
          sexualnoinformaempeoraria: values?.sexualnoinformaempeoraria || "",
        }}
        enableReinitialize={true}
        onSubmit={(values) => {
          localStorage.setItem("sexual", JSON.stringify(values));
          navigation("/dinero");
        }}
        validationSchema={validateSchema}
      >
        {({ values, handleChange, resetForm, setFieldValue, touched, errors }) => (
          <Form>
            <div className="grid grid-cols-1 gap-y-6 md:grid-cols-12 gap-x-6 p-2">
              <div className="col-span-12">
                <Select
                  label="5. ¿Alguien lo/la ha estado tocando en sus partes íntimas y/o lo/la ha obligado/a a tener algún contacto sexual sin su consentimiento?"
                  name="sexual"
                  value={values.sexual}
                  onChange={(e) => {
                    handleChange(e);
                    if (e.target.value === "No") {
                      const sexual = e.target.value;
                      setvalues({
                        sexual: sexual,
                        sexualmeses: "",
                        sexualhijos: "",
                        sexualnietos: "",
                        sexualpareja: "",
                        sexualyernonuera: "",
                        sexualotros: "",
                        sexualtestigo: "",
                        sexualintoxicado: "",
                        sexualeconomico: "",
                        sexualdomicilio: "",
                        sexualdefensa: "",
                        sexualdefensahijos: "",
                        sexualdefensanietos: "",
                        sexualdefensapareja: "",
                        sexualdefensayerno: "",
                        sexualdefensaotros: "",
                        sexualinforma: "",
                        sexualinformafamiliares: "",
                        sexualinformaamigos: "",
                        sexualinformasacerdote: "",
                        sexualinformahospital: "",
                        sexualinformabienestar: "",
                        sexualinformapolicia: "",
                        sexualnoinformanosabe: "",
                        sexualnoinformanoapoyo: "",
                        sexualnoinformanoperjudicar: "",
                        sexualnoinformaempeoraria: "",
                      });
                      resetForm({
                        values: {
                          sexual: sexual,
                          sexualmeses: "",
                          sexualhijos: "",
                          sexualnietos: "",
                          sexualpareja: "",
                          sexualyernonuera: "",
                          sexualotros: "",
                          sexualtestigo: "",
                          sexualintoxicado: "",
                          sexualeconomico: "",
                          sexualdomicilio: "",
                          sexualdefensa: "",
                          sexualdefensahijos: "",
                          sexualdefensanietos: "",
                          sexualdefensapareja: "",
                          sexualdefensayerno: "",
                          sexualdefensaotros: "",
                          sexualinforma: "",
                          sexualinformafamiliares: "",
                          sexualinformaamigos: "",
                          sexualinformasacerdote: "",
                          sexualinformahospital: "",
                          sexualinformabienestar: "",
                          sexualinformapolicia: "",
                          sexualnoinformanosabe: "",
                          sexualnoinformanoapoyo: "",
                          sexualnoinformanoperjudicar: "",
                          sexualnoinformaempeoraria: "",
                        },
                      });
                    }
                  }}
                  array={SINO}
                />
                {touched.sexual && errors.sexual ? (
                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                    <ErrorIcon fontSize="small" /> {errors.sexual}
                  </div>
                ) : null}
              </div>
              {values.sexual === "Si" && (
                <>
                  <div className="col-span-12">
                    <Input
                      label="5.2 ¿Desde hace cuánto tiempo ocurre esto? (indica con palabras, si años, meses o días)"
                      name="sexualmeses"
                      type="text"
                      placeholder="Inserta cuanto tiempo"
                      value={values.sexualmeses}
                      onChange={handleChange}
                    />
                    {touched.sexualmeses && errors.sexualmeses ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.sexualmeses}
                      </div>
                    ) : null}
                  </div>
                  <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                    <h2 className="text-base font-bold leading-7 text-gray-900">
                      ¿Quién lo hizo y cuántas veces ocurrió en el último mes?
                    </h2>
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Hijos/Hijas"
                      placeholder="Hijos"
                      name="sexualhijos"
                      value={values.sexualhijos}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.sexualhijos && errors.sexualhijos ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.sexualhijos}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Nietos/Nietas"
                      name="sexualnietos"
                      placeholder="Nietos"
                      value={values.sexualnietos}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.sexualnietos && errors.sexualnietos ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.sexualnietos}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Pareja"
                      placeholder="Pareja"
                      name="sexualpareja"
                      value={values.sexualpareja}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.sexualpareja && errors.sexualpareja ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.sexualpareja}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Yerno/Nuera"
                      name="sexualyernonuera"
                      placeholder="Yerno / Nuera"
                      value={values.sexualyernonuera}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.sexualyernonuera && errors.sexualyernonuera ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.sexualyernonuera}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Otros"
                      name="sexualotros"
                      placeholder="Otros"
                      value={values.sexualotros}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.sexualotros && errors.sexualotros ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.sexualotros}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Select
                      label="No sabe, pero lo atestiguó"
                      name="sexualtestigo"
                      value={values.sexualtestigo}
                      onChange={handleChange}
                      array={SINO}
                    />
                    {touched.sexualtestigo && errors.sexualtestigo ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.sexualtestigo}
                      </div>
                    ) : null}
                  </div>
                  {familia === "Si" && (
                    <>
                      <div className="border-t col-span-12"></div>
                      <div className="col-span-12">
                        <Select
                          label="5.3 ¿Estos episodios ocurren cuando el agresor se encuentra intoxicado (tomó alcohol, alguna droga)?"
                          name="sexualintoxicado"
                          value={values.sexualintoxicado}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.sexualintoxicado && errors.sexualintoxicado ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.sexualintoxicado}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="¿El/la/los agresores viven en el mismo domicilio que la PAM?"
                          name="sexualdomicilio"
                          value={values.sexualdomicilio}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.sexualdomicilio && errors.sexualdomicilio ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.sexualdomicilio}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="¿Estos episodios ocurren cuando el agresor tiene problemas económicos?"
                          name="sexualeconomico"
                          value={values.sexualeconomico}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.sexualeconomico && errors.sexualeconomico ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.sexualeconomico}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-12">
                        <Select
                          label="Cuando estos episodios ocurren, ¿alguien entra en su defensa?"
                          name="sexualdefensa"
                          value={values.sexualdefensa}
                          onChange={(e) => {
                            handleChange(e);
                            if (e.target.value === "No") {
                              setFieldValue('sexualdefensahijos', "")
                              setFieldValue('sexualdefensanietos', "")
                              setFieldValue('sexualdefensapareja', "")
                              setFieldValue('sexualdefensayerno', "")
                              setFieldValue('sexualdefensaotros', "")
                            }
                          }}
                          array={SINO}
                        />
                        {touched.sexualdefensa && errors.sexualdefensa ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.sexualdefensa}
                          </div>
                        ) : null}
                      </div>
                      {values.sexualdefensa == "Si" && (
                        <>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Hijos/Hijas"
                              name="sexualdefensahijos"
                              placeholder="Hijos"
                              value={values.sexualdefensahijos}
                              onChange={handleChange}
                              type="text"
                            />
                            {touched.sexualdefensahijos && errors.sexualdefensahijos ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.sexualdefensahijos}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Nietos/Nietas"
                              name="sexualdefensanietos"
                              placeholder="Nietos"
                              value={values.sexualdefensanietos}
                              onChange={handleChange}
                              type="text"
                            />
                            {touched.sexualdefensanietos && errors.sexualdefensanietos ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.sexualdefensanietos}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Pareja/esposo/esposa"
                              name="sexualdefensapareja"
                              placeholder="Pareja/esposo/esposa"
                              value={values.sexualdefensapareja}
                              onChange={handleChange}
                              type="text"
                            />
                            {touched.sexualdefensapareja && errors.sexualdefensapareja ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.sexualdefensapareja}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Yerno/Nuera"
                              name="sexualdefensayerno"
                              placeholder="Yerno"
                              value={values.sexualdefensayerno}
                              onChange={handleChange}
                              type="text"
                            />
                            {touched.sexualdefensayerno && errors.sexualdefensayerno ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.sexualdefensayerno}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Otras personas que no son familiares directos"
                              name="sexualdefensaotros"
                              placeholder="Otros"
                              value={values.sexualdefensaotros}
                              onChange={handleChange}
                              type="text"
                            />
                            {touched.sexualdefensaotros && errors.sexualdefensaotros ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.sexualdefensaotros}
                              </div>
                            ) : null}
                          </div>
                        </>
                      )}
                      <div className="border-t col-span-12"></div>
                      <div className="col-span-12">
                        <Select
                          label="5.4 Antes de acudir al DIF, ¿La PAM a quién informó de esta situación? (indique todos los casos que apliquen)"
                          name="sexualinforma"
                          value={values.sexualinforma}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.sexualinforma && errors.sexualinforma ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.sexualinforma}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Familiares"
                          name="sexualinformafamiliares"
                          value={values.sexualinformafamiliares}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.sexualinformafamiliares && errors.sexualinformafamiliares ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.sexualinformafamiliares}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Amigos"
                          name="sexualinformaamigos"
                          value={values.sexualinformaamigos}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.sexualinformaamigos && errors.sexualinformaamigos ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.sexualinformaamigos}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Sacerdote"
                          name="sexualinformasacerdote"
                          value={values.sexualinformasacerdote}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.sexualinformasacerdote && errors.sexualinformasacerdote ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.sexualinformasacerdote}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Hospital"
                          name="sexualinformahospital"
                          value={values.sexualinformahospital}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.sexualinformahospital && errors.sexualinformahospital ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.sexualinformahospital}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Bienestar Social"
                          name="sexualinformabienestar"
                          value={values.sexualinformabienestar}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.sexualinformabienestar && errors.sexualinformabienestar ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.sexualinformabienestar}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Policia"
                          name="sexualinformapolicia"
                          value={values.sexualinformapolicia}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.sexualinformapolicia && errors.sexualinformapolicia ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.sexualinformapolicia}
                          </div>
                        ) : null}
                      </div>
                      {
                        values.sexualinforma == "No" && (
                          <>
                            <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                              <h2 className="text-base font-bold leading-7 text-gray-900">
                                Si no informó, ¿por qué no lo hizo?
                              </h2>
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Select
                                label="No sabía a quién acudir"
                                name="sexualnoinformanosabe"
                                value={values.sexualnoinformanosabe}
                                onChange={handleChange}
                                array={SINO}
                              />
                              {touched.sexualnoinformanosabe && errors.sexualnoinformanosabe ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.sexualnoinformanosabe}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Select
                                label="Había informado en casos de violencia anteriores, pero no recibí apoyo"
                                name="sexualnoinformanoapoyo"
                                value={values.sexualnoinformanoapoyo}
                                onChange={handleChange}
                                array={SINO}
                              />
                              {touched.sexualnoinformanoapoyo && errors.sexualnoinformanoapoyo ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.sexualnoinformanoapoyo}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Select
                                label="No quiero perjudicar a mis familiares"
                                name="sexualnoinformanoperjudicar"
                                value={values.sexualnoinformanoperjudicar}
                                onChange={handleChange}
                                array={SINO}
                              />
                              {touched.sexualnoinformanoperjudicar && errors.sexualnoinformanoperjudicar ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.sexualnoinformanoperjudicar}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Select
                                label="Empeoraría la situación"
                                name="sexualnoinformaempeoraria"
                                value={values.sexualnoinformaempeoraria}
                                onChange={handleChange}
                                array={SINO}
                              />
                              {touched.sexualnoinformaempeoraria && errors.sexualnoinformaempeoraria ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.sexualnoinformaempeoraria}
                                </div>
                              ) : null}
                            </div>
                          </>
                        )
                      }
                    </>
                  )}
                </>
              )}
            </div>
            <div className="flex justify-end mt-6 gap-5">
              <button
                type="button"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-claret-800 hover:bg-claret-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => {
                  navigation("/sexual");
                }}
              >
                Regresar
              </button>
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-claret-900 hover:bg-claret-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Siguiente
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
