/* eslint-disable eqeqeq */
import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Input from "../../components/Input";
import Select from "../../components/Select";
import { SINO } from "../../utils/selectors";
import { useNavigate } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";

export default function Vp() {
  const navigation = useNavigate();
  const [values, setvalues] = React.useState({});
  const [familia, setFamilia] = React.useState();

  React.useEffect(() => {
    const vp = localStorage.getItem("vp");
    if (vp) {
      setvalues(JSON.parse(vp));
    }
    return () => { };
  }, []);

  React.useEffect(() => {
    const esFamilia = localStorage.getItem("familiar");
    setFamilia(esFamilia);
  }, []);

  const validateSchema = Yup.object({
    vp: Yup.string().required("Campo requerido"),
    vpmeses: Yup.string().when("vp", {
      is: "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vphijos: Yup.string().when("vp", {
      is: "Si",
      then: () => Yup.number().typeError('Sólo números').required("Campo requerido"),
    }),
    vpnietos: Yup.string().when("vp", {
      is: "Si",
      then: () => Yup.number().typeError('Sólo números').required("Campo requerido"),
    }),
    vppareja: Yup.string().when("vp", {
      is: "Si",
      then: () => Yup.number().typeError('Sólo números').required("Campo requerido"),
    }),
    vpyernonuera: Yup.string().when("vp", {
      is: "Si",
      then: () => Yup.number().typeError('Sólo números').required("Campo requerido"),
    }),
    vpotros: Yup.string().when("vp", {
      is: "Si",
      then: () => Yup.number().typeError('Sólo números').required("Campo requerido"),
    }),
    vptestigo: Yup.string().when("vp", {
      is: "Si",
      then: () => Yup.string().required("Campo requerido"),
    }),
    vpintoxicado:
      familia === "Si" &&
      Yup.string().when("vp", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    vpdomicilio:
      familia === "Si" &&
      Yup.string().when("vp", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    vpeconomico:
      familia === "Si" &&
      Yup.string().when("vp", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    vpdefensa:
      familia == "Si" &&
      Yup.string().when("vp", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    vpdefensahijos:
      familia == "Si" &&
      Yup.string().when("vp", {
        is: "Si",
        then: () => Yup.string().when("vpdefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    vpdefensanietos:
      familia == "Si" &&
      Yup.string().when("vp", {
        is: "Si",
        then: () => Yup.string().when("vpdefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    vpdefensapareja:
      familia == "Si" &&
      Yup.string().when("vp", {
        is: "Si",
        then: () => Yup.string().when("vpdefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    vpdefensayerno:
      familia == "Si" &&
      Yup.string().when("vp", {
        is: "Si",
        then: () => Yup.string().when("vpdefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    vpdefensaotros:
      familia == "Si" &&
      Yup.string().when("vp", {
        is: "Si",
        then: () => Yup.string().when("vpdefensa", {
          is: "Si",
          then: () => Yup.string().required("Campo requerido"),
        }),
      }),
    vpinforma:
      familia === "Si" &&
      Yup.string().when("vp", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    vpinformafamiliares:
      familia === "Si" &&
      Yup.string().when("vp", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    vpinformaamigos:
      familia === "Si" &&
      Yup.string().when("vp", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    vpinformasacerdote:
      familia === "Si" &&
      Yup.string().when("vp", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    vpinformahospital:
      familia === "Si" &&
      Yup.string().when("vp", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    vpinformabienestar:
      familia === "Si" &&
      Yup.string().when("vp", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    vpinformapolicia:
      familia === "Si" &&
      Yup.string().when("vp", {
        is: "Si",
        then: () => Yup.string().required("Campo requerido"),
      }),
    vpnoinformanosabe:
      familia == "Si" && Yup.string()
        .when(["vp", "vpinforma"], {
          is: (vp, vpinforma) => vp == "Si" && vpinforma == "No",
          then: () => Yup.string().required("Campo requerido"),
        }),
    vpnoinformanoapoyo:
      familia == "Si" && Yup.string()
        .when(["vp", "vpinforma"], {
          is: (vp, vpinforma) => vp == "Si" && vpinforma == "No",
          then: () => Yup.string().required("Campo requerido"),
        }),
    vpnoinformanoperjudicar:
      familia == "Si" && Yup.string()
        .when(["vp", "vpinforma"], {
          is: (vp, vpinforma) => vp == "Si" && vpinforma == "No",
          then: () => Yup.string().required("Campo requerido"),
        }),
    vpnoinformaempeoraria:
      familia == "Si" && Yup.string()
        .when(["vp", "vpinforma"], {
          is: (vp, vpinforma) => vp == "Si" && vpinforma == "No",
          then: () => Yup.string().required("Campo requerido"),
        })
  })

  return (
    <div>
      <Formik
        initialValues={{
          vp: values?.vp || "",
          vpmeses: values?.vpmeses || "",
          vphijos: values?.vphijos || "",
          vpnietos: values?.vpnietos || "",
          vppareja: values?.vppareja || "",
          vpyernonuera: values?.vpyernonuera || "",
          vpotros: values?.vpotros || "",
          vptestigo: values?.vptestigo || "",
          vpintoxicado: values?.vpintoxicado || "",
          vpeconomico: values?.vpeconomico || "",
          vpdomicilio: values?.vpdomicilio || "",
          vpdefensa: values?.vpdefensa || "",
          vpdefensahijos: values?.vpdefensahijos || "",
          vpdefensanietos: values?.vpdefensanietos || "",
          vpdefensapareja: values?.vpdefensapareja || "",
          vpdefensayerno: values?.vpdefensayerno || "",
          vpdefensaotros: values?.vpdefensaotros || "",
          vpinforma: values?.vpinforma || "",
          vpinformafamiliares: values?.vpinformafamiliares || "",
          vpinformaamigos: values?.vpinformaamigos || "",
          vpinformasacerdote: values?.vpinformasacerdote || "",
          vpinformahospital: values?.vpinformahospital || "",
          vpinformabienestar: values?.vpinformabienestar || "",
          vpinformapolicia: values?.vpinformapolicia || "",
          vpnoinformanosabe: values?.vpnoinformanosabe || "",
          vpnoinformanoapoyo: values?.vpnoinformanoapoyo || "",
          vpnoinformanoperjudicar: values?.vpnoinformanoperjudicar || "",
          vpnoinformaempeoraria: values?.vpnoinformaempeoraria || "",
        }}
        enableReinitialize={true}
        onSubmit={(values) => {
          localStorage.setItem("vp", JSON.stringify(values));
          navigation("/sexual");
        }}
        validationSchema={validateSchema}
      >
        {({ values, handleChange, resetForm, setFieldValue, touched, errors }) => (
          <Form>
            <div className="grid grid-cols-1 gap-y-6 md:grid-cols-12 gap-x-6 p-2">
              <div className="col-span-12">
                <Select
                  label="4. Recientemente…alguien con quien habitualmente convive... ¿le ha gritado en repetidas ocasiones, lo humilla/hace sentir menos, lo trata como niño?"
                  name="vp"
                  value={values.vp}
                  onChange={(e) => {
                    handleChange(e);
                    if (e.target.value === "No") {
                      const vp = e.target.value;
                      setvalues({
                        vp: vp,
                        vpmeses: "",
                        vphijos: "",
                        vpnietos: "",
                        vppareja: "",
                        vpyernonuera: "",
                        vpotros: "",
                        vptestigo: "",
                        vpintoxicado: "",
                        vpeconomico: "",
                        vpdomicilio: "",
                        vpdefensa: "",
                        vpdefensahijos: "",
                        vpdefensanietos: "",
                        vpdefensapareja: "",
                        vpdefensayerno: "",
                        vpdefensaotros: "",
                        vpinforma: "",
                        vpinformafamiliares: "",
                        vpinformaamigos: "",
                        vpinformasacerdote: "",
                        vpinformahospital: "",
                        vpinformabienestar: "",
                        vpinformapolicia: "",
                        vpnoinformanosabe: "",
                        vpnoinformanoapoyo: "",
                        vpnoinformanoperjudicar: "",
                        vpnoinformaempeoraria: "",
                      });
                      resetForm({
                        values: {
                          vp: vp,
                          vpmeses: "",
                          vphijos: "",
                          vpnietos: "",
                          vppareja: "",
                          vpyernonuera: "",
                          vpotros: "",
                          vptestigo: "",
                          vpintoxicado: "",
                          vpeconomico: "",
                          vpdomicilio: "",
                          vpdefensa: "",
                          vpdefensahijos: "",
                          vpdefensanietos: "",
                          vpdefensapareja: "",
                          vpdefensayerno: "",
                          vpdefensaotros: "",
                          vpinforma: "",
                          vpinformafamiliares: "",
                          vpinformaamigos: "",
                          vpinformasacerdote: "",
                          vpinformahospital: "",
                          vpinformabienestar: "",
                          vpinformapolicia: "",
                          vpnoinformanosabe: "",
                          vpnoinformanoapoyo: "",
                          vpnoinformanoperjudicar: "",
                          vpnoinformaempeoraria: "",
                        },
                      });
                    }
                  }}
                  array={SINO}
                />
                {touched.vp && errors.vp ? (
                  <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                    <ErrorIcon fontSize="small" /> {errors.vp}
                  </div>
                ) : null}
              </div>
              {values.vp === "Si" && (
                <>
                  <div className="col-span-12">
                    <Input
                      label="4.2 ¿Desde hace cuánto tiempo ocurre esto? (indica con palabras, si años, meses o días)"
                      name="vpmeses"
                      type="text"
                      placeholder="Inserta cuanto tiempo"
                      value={values.vpmeses}
                      onChange={handleChange}
                    />
                    {touched.vpmeses && errors.vpmeses ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.vpmeses}
                      </div>
                    ) : null}
                  </div>
                  <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                    <h2 className="text-base font-bold leading-7 text-gray-900">
                      ¿Quién lo hizo y cuántas veces ocurrió en el último mes?
                    </h2>
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Hijos/Hijas"
                      name="vphijos"
                      placeholder="Hijos"
                      value={values.vphijos}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.vphijos && errors.vphijos ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.vphijos}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Nietos/Nietas"
                      name="vpnietos"
                      placeholder="Nietos"
                      value={values.vpnietos}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.vpnietos && errors.vpnietos ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.vpnietos}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Pareja"
                      name="vppareja"
                      placeholder="Pareja"
                      value={values.vppareja}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.vppareja && errors.vppareja ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.vppareja}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Yerno/Nuera"
                      name="vpyernonuera"
                      placeholder="Yerno"
                      value={values.vpyernonuera}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.vpyernonuera && errors.vpyernonuera ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.vpyernonuera}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Input
                      label="Otros"
                      name="vpotros"
                      placeholder="Otros"
                      value={values.vpotros}
                      onChange={handleChange}
                      type="text"
                    />
                    {touched.vpotros && errors.vpotros ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.vpotros}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-span-12 lg:col-span-6">
                    <Select
                      label="No sabe, pero lo atestiguó"
                      name="vptestigo"
                      value={values.vptestigo}
                      onChange={handleChange}
                      array={SINO}
                    />
                    {touched.vptestigo && errors.vptestigo ? (
                      <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                        <ErrorIcon fontSize="small" /> {errors.vptestigo}
                      </div>
                    ) : null}
                  </div>
                  {familia === "Si" && (
                    <>
                      <div className="border-t col-span-12"></div>
                      <div className="col-span-12">
                        <Select
                          label="4.3 ¿Los gritos, hacerlo sentir menos, el trato infantil, ocurre cuando el agresor se encuentra intoxicado (tomó alcohol, alguna droga)?"
                          name="vpintoxicado"
                          value={values.vpintoxicado}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.vpintoxicado && errors.vpintoxicado ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.vpintoxicado}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="¿El/la/los agresores comparten el domicilio que la PAM objeto de violencia?"
                          name="vpdomicilio"
                          value={values.vpdomicilio}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.vpdomicilio && errors.vpdomicilio ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.vpdomicilio}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="¿Estos episodios ocurren cuando el agresor tiene problemas económicos?"
                          name="vpeconomico"
                          value={values.vpeconomico}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.vpeconomico && errors.vpeconomico ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.vpeconomico}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-12">
                        <Select
                          label="Cuando estos episodios ocurren, ¿alguien entra en su defensa?"
                          name="vpdefensa"
                          value={values.vpdefensa}
                          onChange={(e) => {
                            handleChange(e);
                            if (e.target.value === "No") {
                              setFieldValue('vpdefensahijos', "")
                              setFieldValue('vpdefensanietos', "")
                              setFieldValue('vpdefensapareja', "")
                              setFieldValue('vpdefensayerno', "")
                              setFieldValue('vpdefensaotros', "")
                            }
                          }}
                          array={SINO}
                        />
                        {touched.vpdefensa && errors.vpdefensa ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.vpdefensa}
                          </div>
                        ) : null}
                      </div>
                      {values.vpdefensa == "Si" && (
                        <>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Hijos/Hijas"
                              name="vpdefensahijos"
                              placeholder="Hijos"
                              value={values.vpdefensahijos}
                              onChange={handleChange}
                              type="text"
                            />
                            {touched.vpdefensahijos && errors.vpdefensahijos ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.vpdefensahijos}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Nietos/Nietas"
                              name="vpdefensanietos"
                              placeholder="Nietos"
                              value={values.vpdefensanietos}
                              onChange={handleChange}
                              type="text"
                            />
                            {touched.vpdefensanietos && errors.vpdefensanietos ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.vpdefensanietos}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Pareja/esposo/esposa"
                              name="vpdefensapareja"
                              placeholder="Pareja/esposo/esposa"
                              value={values.vpdefensapareja}
                              onChange={handleChange}
                              type="text"
                            />
                            {touched.vpdefensapareja && errors.vpdefensapareja ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.vpdefensapareja}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Yerno/Nuera"
                              name="vpdefensayerno"
                              placeholder="Yerno"
                              value={values.vpdefensayerno}
                              onChange={handleChange}
                              type="text"
                            />
                            {touched.vpdefensayerno && errors.vpdefensayerno ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.vpdefensayerno}
                              </div>
                            ) : null}
                          </div>
                          <div className="col-span-12 lg:col-span-6">
                            <Input
                              label="Otras personas que no son familiares directos"
                              name="vpdefensaotros"
                              placeholder="Otros"
                              value={values.vpdefensaotros}
                              onChange={handleChange}
                              type="text"
                            />
                            {touched.vpdefensaotros && errors.vpdefensaotros ? (
                              <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                <ErrorIcon fontSize="small" /> {errors.vpdefensaotros}
                              </div>
                            ) : null}
                          </div>
                        </>
                      )}
                      <div className="border-t col-span-12"></div>
                      <div className="col-span-12">
                        <Select
                          label="4.4 Antes de acudir al DIF, ¿La PAM a quién informó de esta situación? (indique todos los casos que apliquen)"
                          name="vpinforma"
                          value={values.vpinforma}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.vpinforma && errors.vpinforma ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.vpinforma}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Familiares"
                          name="vpinformafamiliares"
                          value={values.vpinformafamiliares}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.vpinformafamiliares && errors.vpinformafamiliares ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.vpinformafamiliares}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Amigos"
                          name="vpinformaamigos"
                          value={values.vpinformaamigos}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.vpinformaamigos && errors.vpinformaamigos ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.vpinformaamigos}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Sacerdote"
                          name="vpinformasacerdote"
                          value={values.vpinformasacerdote}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.vpinformasacerdote && errors.vpinformasacerdote ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.vpinformasacerdote}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Hospital"
                          name="vpinformahospital"
                          value={values.vpinformahospital}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.vpinformahospital && errors.vpinformahospital ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.vpinformahospital}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Bienestar Social"
                          name="vpinformabienestar"
                          value={values.vpinformabienestar}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.vpinformabienestar && errors.vpinformabienestar ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.vpinformabienestar}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-span-12 lg:col-span-6">
                        <Select
                          label="Policia"
                          name="vpinformapolicia"
                          value={values.vpinformapolicia}
                          onChange={handleChange}
                          array={SINO}
                        />
                        {touched.vpinformapolicia && errors.vpinformapolicia ? (
                          <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                            <ErrorIcon fontSize="small" /> {errors.vpinformapolicia}
                          </div>
                        ) : null}
                      </div>
                      {
                        values.vpinforma == "No" && (
                          <>
                            <div className="border-b border-t border-gray-900/10 pb-6 pt-6 mt-6 mb-6 col-span-12">
                              <h2 className="text-base font-bold leading-7 text-gray-900">
                                Si no informó, ¿por qué no lo hizo?
                              </h2>
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Select
                                label="No sabía a quién acudir"
                                name="vpnoinformanosabe"
                                value={values.vpnoinformanosabe}
                                onChange={handleChange}
                                array={SINO}
                              />
                              {touched.vpnoinformanosabe && errors.vpnoinformanosabe ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.vpnoinformanosabe}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Select
                                label="Había informado en casos de violencia anteriores, pero no recibí apoyo"
                                name="vpnoinformanoapoyo"
                                value={values.vpnoinformanoapoyo}
                                onChange={handleChange}
                                array={SINO}
                              />
                              {touched.vpnoinformanoapoyo && errors.vpnoinformanoapoyo ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.vpnoinformanoapoyo}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Select
                                label="No quiero perjudicar a mis familiares"
                                name="vpnoinformanoperjudicar"
                                value={values.vpnoinformanoperjudicar}
                                onChange={handleChange}
                                array={SINO}
                              />
                              {touched.vpnoinformanoperjudicar && errors.vpnoinformanoperjudicar ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.vpnoinformanoperjudicar}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-span-12 lg:col-span-6">
                              <Select
                                label="Empeoraría la situación"
                                name="vpnoinformaempeoraria"
                                value={values.vpnoinformaempeoraria}
                                onChange={handleChange}
                                array={SINO}
                              />
                              {touched.vpnoinformaempeoraria && errors.vpnoinformaempeoraria ? (
                                <div className="py-1 px-1 mt-1 bg-red-500 rounded-md shadow text-white text-sm">
                                  <ErrorIcon fontSize="small" /> {errors.vpnoinformaempeoraria}
                                </div>
                              ) : null}
                            </div>
                          </>
                        )}
                    </>
                  )}
                </>
              )}
            </div>
            <div className="flex justify-end mt-6 gap-5">
              <button
                type="button"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-claret-800 hover:bg-claret-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => {
                  navigation("/abandona");
                }}
              >
                Regresar
              </button>
              <button
                type="submit"
                className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-claret-900 hover:bg-claret-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Siguiente
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
